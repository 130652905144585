<template>
	<div class="safe_page">
		<div class="bg_white">
			<el-row class="p2 c_black" type="flex" align="middle">
				<el-col :span=4 class="c_blue tc" style="font-size:30px;">
					<router-link to="/" class="c_black">
						<i class="fa fa-angle-left" aria-hidden="true"></i>
					</router-link>
				</el-col>
				<el-col :span="16" class="f16 c_black2">
					{{$t("风险提示")}}
				</el-col>
				<el-col :span=4 class="">
					<span></span>
				</el-col>
			</el-row>
		</div>
		
		<div class="m4">
			<el-card class="box-card tl lh18">
				<div v-html="content"></div>
			</el-card>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'SafeView',
		data(){
			return {
				content: "",
				title: "",
			}
		},
		mounted: function(){
			// get content
			let that = this;
			this.greq('post', '/main/content', {type:5}, function(rs){
				if( !rs.status ){
					this.$message({message:rs.msg, type:'error'});
				} else {
					that.content = rs.data.content;
					that.title = rs.data.title;
				}
			});
		},
	}
</script>

<style>
</style>