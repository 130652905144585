<template>
	<div class="earn_detail_page tl" v-loading.fullscreen.lock="loading" element-loading-background="rgba(0,0,0,0.4)">
		<!--header-->
		<div class="bg_white borderb">
			<el-row class="p2 c_black2" type="flex" align="middle">
				<el-col :span=4 class="c_blue tc" style="font-size:30px;">
					<router-link to="/earn" class="c_black">
						<i class="fa fa-angle-left" aria-hidden="true"></i>
					</router-link>
				</el-col>
				<el-col :span=16 class="f16">
					<el-row type="flex" align="middle">
                        <el-col :span="12" class="tr">
                            <img src="../assets/usdt.png" style="width: 20px;padding-right:10px;" alt="">
                        </el-col>
                        <el-col :span="12" class="tl">USDT</el-col>
                    </el-row>
				</el-col>
				<el-col :span=4 class="">
					<span></span>
				</el-col>
			</el-row>
		</div>

        <!--form-->
        <div class="p4 bg_white">
            <!-- info -->
            <div class="bg_gray br4">
                <div class="p4">
                    <el-row>
                        <el-col :span="12" class="tl">
                            <span class="c_gray">{{$t('期限')}}</span>
                        </el-col>
                        <el-col :span="12" class="tr">
                            {{cycle}} {{$t('天')}}
                        </el-col>
                    </el-row>

                    <el-row class="pt2">
                        <el-col :span="12" class="tl">
                            <span class="c_gray">{{$t('收益率')}}</span>
                        </el-col>
                        <el-col :span="12" class="tr">
                            {{ror}}%
                        </el-col>
                    </el-row>
                </div>
            </div>

            <!-- input -->
            <div class="pt5">
                <div class="fbold">{{$t('数量')}}</div>

                <div style="border:1px solid #ccc;" class="br4 mt3">
                    <el-row type="flex" align="middle">
                        <el-col :span="16">
                            <el-input class="w100" v-model="amount" :placeholder="$t('最低')+' '+min"></el-input>
                        </el-col>

                        <el-col :span="4" class="tr">
                            USDT
                        </el-col>
                        <el-col :span="4" class="tc">
                            <el-button round size="mini" @click="set_all">{{$t('全部')}}</el-button>
                        </el-col>
                    </el-row>
                </div>

                <div class="mt2 f12">
                    <span class="c_gray">{{$t('可用余额')}}</span>
                    <span> {{balance}} USDT</span>
                    
                </div>
            </div>

            <!--rule-->
            <div class="pt5">
                <div class="fbold pb3">{{$t('规则')}}</div>

                <div class="f12">
                    <el-row>
                        <el-col :span="12" class="tl">
                            <span class="c_gray">{{$t('申购时间')}}</span>
                        </el-col>
                        <el-col :span="12" class="tr">
                            {{start_time}}
                        </el-col>
                    </el-row>

                    <el-row class="pt2">
                        <el-col :span="12" class="tl">
                            <span class="c_gray">{{$t('起息时间')}}</span>
                        </el-col>
                        <el-col :span="12" class="tr">
                            {{start_time}}
                        </el-col>
                    </el-row>

                    <el-row class="pt2">
                        <el-col :span="12" class="tl">
                            <span class="c_gray">{{$t('收益发放时间')}}</span>
                        </el-col>
                        <el-col :span="12" class="tr">
                            {{reward_time}}
                        </el-col>
                    </el-row>

                    <el-row class="pt2">
                        <el-col :span="12" class="tl">
                            <span class="c_gray">{{$t('到期时间')}}</span>
                        </el-col>
                        <el-col :span="12" class="tr">
                            {{end_time}}
                        </el-col>
                    </el-row>
                </div>
            </div>

            <!--btn-->
            <div class="pt5 mt5 tc">
                <el-button type="primary" class="mt4 w100" :disabled="amount>=min?false:true" round @click="submit" v-if="show_result_btn==false">{{$t('申购')}}</el-button>


                <el-button type="primary" class="mt4 w100" :disabled="disable_result_btn" round v-if="show_result_btn">{{$t('正在提交')}}</el-button>
            </div>
        </div>


        <el-dialog title="" :visible.sync="show_result_dialog" :close-on-click-modal="false" width="70%" center @closed="to_history">
			<div class="tc">
					<i class="el-icon-circle-check" style="font-size: 30px;display: block;color: #2abb46;background-color: #2abb46;width: 30px;height: 30px;margin: 0 auto;color: #fff;border-radius: 15px;"></i>
					<div class="mt2">{{$t('申购成功')}}</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: 'EarnDetail',
		data(){
			return {
                min: 100,
                ror: 0.00,
                cycle: 0,
                amount: '',
                balance: 0,

                show_result_btn: false,
                disable_result_btn: true,

                show_result_dialog: false
			}
		},
		computed: {
            start_time: function(){
                const now = new Date();

                const year = now.getFullYear();
                const month = String(now.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以要加1
                const day = String(now.getDate()).padStart(2, '0');
                const hours = String(now.getHours()).padStart(2, '0');
                const minutes = String(now.getMinutes()).padStart(2, '0');

                return `${year}/${month}/${day} ${hours}:${minutes}`;
            },
            reward_time: function(){
                const now = new Date();
                now.setDate(now.getDate() + parseInt(this.cycle));

                const year = now.getFullYear();
                const month = String(now.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以要加1
                const day = String(now.getDate()).padStart(2, '0');

                return `${year}/${month}/${day}`;
            },

            end_time: function(){
                const now = new Date();
                now.setDate(now.getDate() + parseInt(this.cycle));

                const year = now.getFullYear();
                const month = String(now.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以要加1
                const day = String(now.getDate()).padStart(2, '0');
                const hours = String(now.getHours()).padStart(2, '0');
                const minutes = String(now.getMinutes()).padStart(2, '0');

                return `${year}/${month}/${day} ${hours}:${minutes}`;
            }
		},
		methods: {
            set_all: function(){
                this.amount = this.balance;
            },

            submit: function(){
                let that = this;
                that.show_result_btn = true;
                this.greq('post', '/api/earn_order', {id:that.id, amount:that.amount}, function(rs){
                    if( !rs.status ){
                        that.show_result_btn = false;
                        that.gerror(rs.msg)
                        return
                    }
                    that.show_result_btn = false;
                    that.show_result_dialog = true;
                })
            },

            to_history: function(){
                this.$router.push('/earn/1')
            }
		},
		mounted: function(){
			// this.$parent.show_footer = false;
			// get miner info
			let that = this;
			this.greq('post', '/api/earn_detail', {id:that.id}, function(rs){
				if( !rs.status ){
					that.gerror(rs.msg)
					return
				}
                that.min = rs.data.min;
                that.ror = rs.data.ror;
                that.cycle = rs.data.cycle;
                that.balance = rs.data.balance;
            })
			// 	that.data = rs.data;
			// 	that.amount = rs.data.min
			// 	that.balance = rs.data.user_money
			// })
		},
		props: ['id'],
		beforeDestroy() {
			// this.$parent.show_footer = true;
		},
	}
</script>

<style>
.earn_detail_page .el-input__inner:hover,.earn_detail_page .el-input__inner{border:1px solid #fff !important;}
</style>