<template>
	<div class="bank_page" v-loading.fullscreen.lock="loading" element-loading-background="rgba(0,0,0,0.4)">
		<div class="bg_white">
			<el-row class="p2 c_black" type="flex" align="middle">
				<el-col :span=4 class="c_blue tc" style="font-size:30px;">
					<router-link to="/" class="c_black">
						<i class="fa fa-angle-left" aria-hidden="true"></i>
					</router-link>
				</el-col>
				<el-col :span=16 class="f16">
					{{$t('绑定银行卡')}}
				</el-col>
				<el-col :span=4 class="">
					<span></span>
				</el-col>
			</el-row>
		</div>

		<div class="m3">
			<div class="bg_white bradius8 p4">
				<el-tabs v-model="activeTab" :stretch=true class="m0">
					<el-tab-pane :label="this.$t('银行卡')" name="t1">

						<div class="mt4">
							<!-- 显示卡列表 -->
							<div v-for="item of bankList" v-bind:key="item.id" class="mt3">
								<div class="bg_blue2 bradius8 c_white">
									<div class="p3 tr">
										<span v-if="item.status==0" class="bank_status f12">{{$t("待审核")}}</span>
										<span v-if="item.status==2" class="bank_status bg_up f12">{{$t("拒绝")}}</span>
										<el-button type="danger" size="small" slot="reference"
											@click="delcard(item.id)">Delete</el-button>
											
									</div>

									<div>
										<el-row type="flex" align="middle">
											<el-col :span="6" class="tr">
												<i class="fa fa-credit-card bg_white tc card_icon"></i>
											</el-col>
											<el-col :span="18" class="tl f18" style="text-indent:20px;">
												{{item.bank}}
											</el-col>
										</el-row>

										<el-row class="p4 f18">
											<el-col :span="6">····</el-col>
											<el-col :span="6">····</el-col>
											<el-col :span="6">····</el-col>
											<el-col :span="6">{{item.account}}</el-col>
										</el-row>
									</div>
								</div>
							</div>
						</div>
						<div class="w100 mt4">
							<el-button class="w100" plain @click="showAddBank=true">
								<i class="fa fa-plus"></i> &nbsp;{{$t("添加银行卡")}}
							</el-button>
						</div>
					</el-tab-pane>

					<!-- 数字地址列表 -->
					<el-tab-pane :label="this.$t('数字地址')" name="t2">
						<!-- 显示列表 -->
						<div class="mt4">
							<div v-for="item of currencyList" v-bind:key="item.id" class="mt3 pb4" style="border-bottom: 1px solid #ddd;">
								<el-row type="flex" align="middle">
									<el-col :span="18" class="tl">
										<div>
											<span class="fbold">{{item.currency}}</span>
											<span v-if="item.status==0" class="bank_status f12">{{$t("待审核")}}</span>
											<span v-if="item.status==2" class="bank_status bg_up f12">{{$t("拒绝")}}</span>
										</div>
										<div class="c_gray mt2">
											{{item.address}}
										</div>
									</el-col>
									
									<el-col :span="6">
										<el-button type="warning" size="small" @click="delcard(item.id)">Delete</el-button>
									</el-col>
								</el-row>
							</div>
						</div>
						
						<div class="w100 mt4">
							<el-button class="w100" plain @click="showAddAddress=true">
								<i class="fa fa-plus"></i> &nbsp;{{$t("添加数字钱包")}}
							</el-button>
						</div>
					</el-tab-pane>
				</el-tabs>
			</div>
		</div>

		<!-- 添加银行卡 -->
		<el-drawer :title="$t('添加银行卡')" :visible.sync="showAddBank" direction="ttb" size="100%">
			<div class="p4">
				<el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
					size="small">
					<el-form-item :label="$t('银行名称')" prop="bank">
						<el-input v-model="form.bank"></el-input>
					</el-form-item>

					<el-form-item :label="$t('银行支行')" prop="branch">
						<el-input v-model="form.branch"></el-input>
					</el-form-item>

					<!-- <el-form-item :label="$t('开户地址')" prop="bank_address">
						<el-input v-model="form.bank_address"></el-input>
					</el-form-item> -->

					<el-form-item :label="$t('银行卡号')" prop="account">
						<el-input v-model="form.account"></el-input>
					</el-form-item>

					<!-- <el-form-item :label="$t('银行代码')" prop="bank_code">
						<el-input v-model="form.bank_code"></el-input>
					</el-form-item> -->

					<el-form-item :label="$t('姓名')" prop="name">
						<el-input v-model="form.name"></el-input>
					</el-form-item>

					<el-form-item :label="$t('身份证ID')" prop="idcard">
						<el-input v-model="form.idcard"></el-input>
					</el-form-item>

					<el-form-item :label="$t('联系号码')" prop="phone">
						<el-input v-model="form.phone"></el-input>
					</el-form-item>

					<el-form-item>
						<el-button size="medium" round type="primary" class="w100 mt4" @click="submitForm('ruleForm', 0)">
							{{$t('开户')}}
						</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-drawer>


		<el-drawer :title="$t('添加数字钱包')" :visible.sync="showAddAddress" direction="ttb" size="100%">
			<div class="p4">
				<el-form :model="form2" :rules="rules2" ref="addrForm" label-width="100px" size="small">
					<el-form-item :label="$t('币种')" prop="currency">
						<el-select v-model="form2.currency" placeholder="">
							<el-option v-for="item in coins" :key="item.label" :label="item.label"
								:value="item.label">
							</el-option>
						</el-select>
					</el-form-item>
					
					<el-form-item :label="$t('地址')" prop="address">
						<el-input v-model="form2.address"></el-input>
					</el-form-item>
					
					<el-form-item :label="$t('备注')" prop="remark">
						<el-input v-model="form2.remark"></el-input>
					</el-form-item>
					
					<el-form-item>
						<el-button size="medium" round type="primary" class="w100 mt4" @click="submitForm('addrForm', 1)">
							{{$t('开户')}}
						</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-drawer>
	</div>
</template>

<script>
	export default {
		name: 'BankView',
		data() {
			return {
				activeTab: "t1",
				showAddBank: false,
				showAddAddress: false,
				bankList: [],
				currencyList: [],
				form: {
					bank: "",
					branch: "",
					// bank_address: "",
					account: "",
					// bank_code: "",
					name: "",
					idcard: "",
					phone: "",
				},
				rules: {
					bank: [{
						required: true,
						message: " ",
						trigger: 'blur'
					}],
					branch: [{
						required: true,
						message: " ",
						trigger: 'blur'
					}],
					// bank_address: [{
					// 	required: true,
					// 	message: " ",
					// 	trigger: 'blur'
					// }],
					account: [{
						required: true,
						message: " ",
						trigger: 'blur'
					}],
					// bank_code: [{
					// 	required: true,
					// 	message: " ",
					// 	trigger: 'blur'
					// }],
					name: [{
						required: true,
						message: " ",
						trigger: 'blur'
					}],
					idcard: [{
						required: true,
						message: " ",
						trigger: 'blur'
					}],
					phone: [{
						required: true,
						message: " ",
						trigger: 'blur'
					}],
				},
				loading: false,
				form2:{
					currency: "",
					address: "",
					remark: "",
				},
				rules2:{
					currency: [{
						required: true,
						message: " ",
						trigger: 'blur'
					}],
					address: [{
						required: true,
						message: " ",
						trigger: 'blur'
					}],
				},
				coins:[
					{label:"USDT ERC20"},
					{label:"USDT TRC20"},
					{label:"USDC"},
					{label:"BUSD"},
					{label:"BTC"},
					{label:"ETH"},
					{label:"XRP"},
					{label:"BNB"},
				]
			}
		},
		methods: {
			submitForm(formName, type) {
				let that = this;
				let data  = type == 0 ? that.form : that.form2
				data.type = type
				
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.greq('post', '/api/addbank', data, function(rs) {
							if (!rs.status) {
								that.$message({
									message: rs.msg,
									type: 'error'
								})
							} else {
								that.$message(rs.msg)

								if( type == 0 ){
									// reset form, and close form
									that.resetForm('ruleForm')
									that.showAddBank = false;
								}else{
									that.resetForm('addrForm')
									that.showAddAddress = false;
								}

								// update data
								that.getBankList();
							}
						})
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},

			// update list
			getBankList() {
				let that = this;

				this.greq('get', '/api/bank', false, function(rs) {
					if (rs.status) {
						let banks = [];
						let currencys = [];
						for (var row of rs.data) {
							if (row.type == 0) {
								banks.push(row);
							} else {
								currencys.push(row);
							}
						}
						that.bankList = banks;
						that.currencyList = currencys;
					}
				});
			},

			// delete card
			delcard(id) {
				console.log(id)
				let that = this;
				// confirm
				this.$confirm(this.$t("确定删除"), "", {
					confirmButtonText: this.$t("确定"),
					cancelButtonText: this.$t("取消"),
					center: true,
					customClass: "w50",
					showClose: false
				}).then(() => {
					that.loading = true;
					that.greq("post", "/api/delbank", {
						id: id
					}, function(rs) {
						that.loading = false
						if (rs.status) {
							that.$message(rs.msg)
							that.getBankList();
						} else {
							that.$message({
								message: rs.msg,
								type: 'error'
							})
						}
					});
				}).catch(() => {
					that.loading = false
				})
			}
		},
		mounted() {
			this.getBankList();
		}
	}
</script>

<style>
	.bank_page .el-form-item {
		text-align: left;
	}

	.bank_page .el-form-item__label {
		float: inherit;
	}

	.bank_page .el-form-item__content {
		margin-left: 0px !important;
	}

	.bank_page .el-form-item--mini.el-form-item,
	.bank_page .el-form-item--small.el-form-item {
		margin-bottom: 6px;
	}
	
	.bank_page .el-select--small{
		width: 100%;
	}

	.bank_page .el-button--danger {
		background-color: transparent;
		border-color: #fff;
	}

	.bank_page .card_icon {
		color: #618EDE;
		width: 40px;
		height: 40px;
		border-radius: 20px;
		line-height: 40px;
		font-size: 24px;
	}

	.bank_del_confirm {
		width: 50% !important;
	}
	
	.bank_page .bank_status{
		margin-left:10px;
		padding:4px 6px;
		background-color: #618EDE;
		color:#fff;
		border-radius: 4px;
		margin-right: 10px;
	}
	
	.bank_page .el-button--warning{
		background-color: transparent;
		border-color: #409eff;
		color:#409eff;
	}
</style>
