<template>
	<div class="minerbuy_page" v-loading.fullscreen.lock="loading" element-loading-background="rgba(0,0,0,0.4)">
		
		
		<div class="" style="width:100%;height:100%;background-color:#0851c3;position:absolute;top:0px;">
			<div class="bg_white">
				<el-row class="p2 c_black2" type="flex" align="middle">
					<el-col :span=4 class="c_blue tc" style="font-size:30px;">
						<router-link to="/miner" class="c_black">
							<i class="fa fa-angle-left" aria-hidden="true"></i>
						</router-link>
					</el-col>
					<el-col :span=16 class="f16">
						{{$t('质押挖矿')}}
					</el-col>
					<el-col :span=4 class="">
						<span></span>
					</el-col>
				</el-row>
			</div>
			
			<div class="m4">
				<div>
					<el-row type="flex" align="middle">
						<el-col :span="4">
							<img src="../assets/avatar.png" width="34px" alt="">
						</el-col>
						<el-col :span="12" class="tl">
							<div class="c_gray f12">{{$t('获利')}}USDT</div>
							<div class="fbold c_white f16 mt2">{{data.title[lang]}}</div>
						</el-col>
						<el-col :span="8" class="tr">
							<div class="c_gray f12">{{$t('收益率')}}</div>
							<div class="c_yellow fbold mt2">{{data.profit}}%</div>
						</el-col>
					</el-row>
				</div>
			</div>
			
			<div class="m3 blue3 bradius8" style="margin-top:40px;">
				<div style="position: relative;top:-20px;height:50px;">
					<div class="m4 pt3 pb3 bg_white bradius8" >
						<el-row>
							<el-col :span="12">
								<div class="fbold">{{data.days}}{{$t('天')}}</div>
								<div class="c_gray f12 mt2">{{$t('理财周期')}}</div>
							</el-col>
							
							<el-col :span="12">
								<div class="fbold">{{data.min}}~{{data.max}}</div>
								<div class="c_gray f12 mt2">{{$t('单笔限制')}}(USDT)</div>
							</el-col>
						</el-row>
					</div>
				</div>
				
				<div style="clear: both;" class="pb3 mt3 f12">
					<el-row>
						<el-col :span="8">
							<div class="c_gray">{{$t('派息时间')}}</div>
							<div class="c_yellow fbold mt2">{{$t('每天')}}</div>
						</el-col>
						<el-col :span="8">
							<div class="c_gray">{{$t('托管资金')}}</div>
							<div class="c_yellow fbold mt2">{{$t('每天结算')}}</div>
						</el-col>
						<el-col :span="8">
							<div class="c_gray">{{$t('提前赎回')}}</div>
							<div class="c_yellow fbold mt2">{{data.cancel_cost}}%</div>
						</el-col>
					</el-row>
				</div>
			</div>
			
			<div class="m3 blue3 bradius8 mt4 c_white">
				<div class="p3">
					<el-row>
						<el-col :span="12">
							<div class="c_gray f12">{{$t('预计收益')}}/{{$t('天')}}(USDT)</div>
							<div class="fbold mt2">{{profit}}</div>
						</el-col>
						
						<el-col :span="12">
							<div class="c_gray f12">{{$t('可用资产')}}(USDT)</div>
							<div class="fbold mt2">{{balance}}</div>
						</el-col>
					</el-row>
				</div>
				
				<div class="p3 tl">
					<div class="f12">{{$t('存入金额')}}</div>
					<div class="mt3">
						<el-input class="w100" size="small" :placeholder="$t('输入存入金额')" v-model="amount" @input="formatNumber"></el-input>
					</div>
				</div>
			</div>
			
		</div>
		
		<!-- footer -->
		<div style="position: absolute;bottom:0px;height:60px;" class="w100">
			<el-button type="primary" plain class="w80" size="small" @click="buy">{{$t('认购')}}</el-button>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'MinerBuy',
		data(){
			return {
				loading: false,
				data: {
					profit: 0
				},
				balance: '-',
				amount: 0,
				lang:this.glang(),
			}
		},
		computed: {
			profit(){
				return (this.amount*this.data.profit/100).toFixed(4)
			}
		},
		methods: {
			formatNumber(){
				let n = parseInt(this.amount)
				if( isNaN(n) ){
					n = 0;
				}
				this.amount = n;
			},
			buy(){
				if(this.amount<this.data.min || this.amount>this.data.max){
					this.gerror(this.$t('超出单笔限制'))
					return
				}
				
				// submit
				let that = this;
				this.greq('post', '/api/miner_buy', {id:this.id, amount:this.amount}, function(rs){
					if( !rs.status ){
						that.gerror(rs.msg);
						return
					}
					
					// goto history
					that.$message(rs.msg)
				});
			}
		},
		mounted: function(){
			this.$parent.show_footer = false;
			// get miner info
			let that = this;
			this.greq('post', '/api/miner_info', {id:that.id}, function(rs){
				if( !rs.status ){
					that.gerror(rs.msg)
					return
				}
				
				that.data = rs.data;
				that.amount = rs.data.min
				that.balance = rs.data.user_money
			})
		},
		props: ['id'],
		beforeDestroy() {
			this.$parent.show_footer = true;
		},
	}
</script>

<style>
	.minerbuy_page .blue3{
		background-color: #2d6bcc;
	}
</style>