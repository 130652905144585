<template>
	<div class="score_page">
		<div class="bg_white borderb">
			<el-row class="p2 c_black" type="flex" align="middle">
				<el-col :span=4 class="c_blue tc" style="font-size:30px;">
					<router-link to="/" class="c_black">
						<i class="fa fa-angle-left" aria-hidden="true"></i>
					</router-link>
				</el-col>
				<el-col :span=16 class="f16 c_black2">
					{{$t("联系客服")}}
				</el-col>
				<el-col :span=4 class="">
					<span></span>
				</el-col>
			</el-row>
		</div>
		
		<div class="m3">
			<div>
				<div style="height:1px;z-index:99">
					<div ref="csbg_text" style="display: flex;place-content: space-around center;flex-flow: column wrap;position: relative;height: 162px;margin: 0px auto;align-items: flex-start;flex-direction: column;flex-wrap: nowrap;" class="c_white w80">
						<div class="f20 ">{{$t('用心聆听')}}</div>
						<div class="f20  pb2 borderb" style="border-bottom-width: 2px;">{{$t('你的声音')}}</div>
						<div class="f13 mt3 ">{{$t('竭诚解决你的问题')}}</div>
					</div>
				</div>
				<div>
					<img src="../assets/cs_bg.jpg" class="w100" alt="" ref="csbg">
				</div>
			</div>
			
			<div class="mt3 p3 br6 bg_white bshadow">
				<div  @click="$router.push('/client_iframe')">
					<el-row type="flex" align="middle">
						<el-col :span="4">
							<i class="el-icon-s-custom f40 c_gray"></i>
						</el-col>
						<el-col :span="18" class="tl">
							<div class="c_blue f16">{{$t('线上客服')}}</div>
							<div class="c_gray f12 mt1">{{$t('点击立即咨询即可沟通')}}</div>
						</el-col>
						<el-col :span="2">
							<i class="el-icon-arrow-right f20 c_gray"></i>
						</el-col>
					</el-row>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "ClientService",
		data(){
			return {
				
			}
		},
		mounted(){
			let that = this;
			setTimeout(function(){
				console.log(that.$refs.csbg.height);
				console.log(that.$refs.csbg_text.style);
				that.$refs.csbg_text.style.height = that.$refs.csbg.height+'px';
			}, 1000);
			
		},
	}
</script>

<style>
</style>