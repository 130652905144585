<template>
	<div class="miner_orders_page" v-loading.fullscreen.lock="loading" element-loading-background="rgba(0,0,0,0.4)">
		<div class="bg_white">
			<el-row class="p2 c_black2" type="flex" align="middle">
				<el-col :span=4 class="c_blue tc" style="font-size:30px;">
					<router-link to="/miner" class="c_black">
						<i class="fa fa-angle-left" aria-hidden="true"></i>
					</router-link>
				</el-col>
				<el-col :span=16 class="f16">
					{{$t('历史记录')}}
				</el-col>
				<el-col :span=4 class="">
					<span></span>
				</el-col>
			</el-row>
		</div>
		
		<div class="p3">
			<el-tabs :stretch=true v-model="activeTab">
				<el-tab-pane :label="$t('托管中')" name="pending">
					<div v-for="item of pendings" v-bind:key="item.id" class="bg_white m3 p3 bradius8" @click="drawer=true;detail=item">
						<el-row type="flex" align="middle">
							<el-col :span="18" class="tl fbold c_blue">
								{{item.title[lang]}} ( {{item.days}}{{$t('天')}} )
							</el-col>
							<el-col :span="6" class="tr">
								<el-button type="primary" size="mini" @click.stop="cancel(item.id)">{{$t('取消')}}</el-button>
							</el-col>
						</el-row>
						
						<el-row class="mt3">
							<el-col :span="12" class="tl">
								<div class="fbold">{{item.amount}}</div>
								<div class="c_gray f12 mt2">{{item.add_time}}</div>
							</el-col>
							<el-col :span="6">
								<div class="fbold c_down">{{item.amount*item.profit/100}}</div>
								<div class="c_gray f12 mt2">{{$t('收益')}}</div>
							</el-col>
							<el-col :span="6">
								<div class="fbold">{{item.days-item.profit_days}}</div>
								<div class="c_gray f12 mt2">{{$t('剩余')}}</div>
							</el-col>
						</el-row>
					</div>
					
					<div v-if="pendings.length==0" class="mt5 c_gray">
						{{$t('暂无记录')}}
					</div>
				</el-tab-pane>
				
				<!-- 已完成 -->
				<el-tab-pane :label="$t('已完成')" name="done">
					<div v-for="item of dones" v-bind:key="item.id" class="bg_white m3 p3 bradius8" @click="drawer=true;detail=item">
						<el-row type="flex" align="middle">
							<el-col :span="24" class="tl fbold c_blue">
								{{item.title[lang]}} ( {{item.days}}{{$t('天')}} )
							</el-col>
						</el-row>
						
						<el-row class="mt3">
							<el-col :span="12" class="tl">
								<div class="fbold">{{item.amount}} USDT</div>
								<div class="c_gray f12 mt2">{{item.add_time}}</div>
							</el-col>
							<el-col :span="12" class="tr">
								<div class="fbold c_down">{{item.profit_get}}</div>
								<div class="c_gray f12 mt2">{{$t('收益')}}</div>
							</el-col>
						</el-row>
					</div>
					
					<div v-if="dones.length==0" class="mt5 c_gray">
						{{$t('暂无记录')}}
					</div>
				</el-tab-pane>
				
				<!-- 已取消 -->
				<el-tab-pane :label="$t('已取消')" name="cancel">
					<div v-for="item of cancels" v-bind:key="item.id" class="bg_white m3 p3 bradius8" @click="drawer=true;detail=item">
						<el-row type="flex" align="middle">
							<el-col :span="24" class="tl fbold c_blue">
								{{item.title[lang]}} ( {{item.days}}{{$t('天')}} )
							</el-col>
						</el-row>
						
						<el-row class="mt3">
							<el-col :span="12" class="tl">
								<div class="fbold">{{item.amount}} USDT</div>
								<div class="c_gray f12 mt2">{{item.add_time}}</div>
							</el-col>
							<el-col :span="12" class="tr">
								<div class="fbold c_up">{{item.amount*item.profit*item.days/100}}</div>
								<div class="c_gray f12 mt2">{{$t('违约金')}}</div>
							</el-col>
						</el-row>
					</div>
					
					<div v-if="cancels.length==0" class="mt5 c_gray">
						{{$t('暂无记录')}}
					</div>
				</el-tab-pane>
			</el-tabs>
		</div>
		
		<!-- order detail -->
		<el-drawer :title="$t('')" :visible.sync="drawer" direction="btt" size="70%">
			<div class="mt3">
				<div class="fbold f18">{{detail.title[lang]}} ( {{detail.days}}{{$t('天')}})</div>
				
				<div class="mt3">
					<el-row type="flex" align="middle">
						<el-col :span="6">
							<img src="../assets/avatar.png" width="50px" alt="">
						</el-col>
						<el-col :span="18" class="tl">
							<div class="c_gray f12">{{$t('托管金额')}}</div>
							<div class="f16 fbold mt2">${{detail.amount}}</div>
						</el-col>
					</el-row>
				</div>
				
				<div class="pt3 pb3 m4" style="border-top:1px solid #eee;border-bottom: 1px solid #eee;">
					<el-row>
						<el-col :span="8">
							<div class="c_gray f12">{{$t('预估收益')}}(USDT)</div>
							<div class="f12 c_up mt2 fbold">{{detail.amount*detail.profit*detail.days/100}}</div>
						</el-col>
						
						<el-col :span="8">
							<div class="c_gray f12">{{$t('收益率')}}/{{$t('天')}}</div>
							<div class="f12 c_up mt2 fbold">{{detail.profit}}%</div>
						</el-col>
						
						<el-col :span="8">
							<div class="c_gray f12">{{$t('托管时间')}}({{$t('天')}})</div>
							<div class="f12 c_up mt2 fbold">{{detail.days}}</div>
						</el-col>
					</el-row>
				</div>
				
				<div class="f12 pl4 pr4">
					<el-row>
						<el-col :span="8" class="tl">{{$t('起息时间')}}</el-col>
						<el-col :span="16" class="tr">{{detail.start}}</el-col>
					</el-row>
					
					<el-row class="mt3">
						<el-col :span="8" class="tl">{{$t('到期时间')}}</el-col>
						<el-col :span="16" class="tr">{{detail.end}}</el-col>
					</el-row>
					
					<el-row class="mt3">
						<el-col :span="8" class="tl">{{$t('已获利')}}</el-col>
						<el-col :span="16" class="tr">{{detail.profit_get}} USDT</el-col>
					</el-row>
					
					<el-row class="mt3">
						<el-col :span="8" class="tl">{{$t('剩余时间')}}</el-col>
						<el-col :span="16" class="tr">{{detail.days-detail.profit_days}}{{$t('天')}}</el-col>
					</el-row>
					
					<el-row class="mt3" v-if="detail.status==0">
						<el-col :span="8" class="tl">{{$t('预计违约金')}}</el-col>
						<el-col :span="16" class="tr">{{detail.amount*detail.cancel_cost*detail.days/100}} USDT</el-col>
					</el-row>
					
					<el-row class="mt3" v-if="detail.status==0">
						<el-col :span="8" class="tl">{{$t('预计退还本金')}}</el-col>
						<el-col :span="16" class="tr">{{detail.amount-(detail.amount*detail.cancel_cost*detail.days/100)}} USDT</el-col>
					</el-row>
					
					<el-row class="mt3" v-if="detail.status==2">
						<el-col :span="8" class="tl">{{$t('违约金')}}</el-col>
						<el-col :span="16" class="tr">{{detail.amount*detail.cancel_cost*detail.days/100}} USDT</el-col>
					</el-row>
					
					<el-row class="mt3" v-if="detail.status==2">
						<el-col :span="8" class="tl">{{$t('退还本金')}}</el-col>
						<el-col :span="16" class="tr">{{detail.amount-(detail.amount*detail.cancel_cost*detail.days/100)}} USDT</el-col>
					</el-row>
					
					<el-row class="mt3">
						<el-col :span="8" class="tl">{{$t('订单时间')}}</el-col>
						<el-col :span="16" class="tr">{{detail.add_time}}</el-col>
					</el-row>
				</div>
			</div>
		</el-drawer>
	</div>
</template>

<script>
	export default {
		name: "MinerOrders",
		data(){
			return {
				loading: false,
				lang: this.glang(),
				activeTab: "pending",
				pendings: [],
				dones: [],
				cancels: [],
				drawer: false,
				detail: {
					title:{}
				},
			}
		},
		methods:{
			cancel(id){
				// confirm
				let that = this;
				this.$confirm(this.$t("确定取消该订单"), "", {
					confirmButtonText: this.$t("确定"),
					cancelButtonText: this.$t("取消"),
					center: true,
					customClass: "w50",
					showClose: false
				}).then(() => {
					that.loading = true
					that.greq('post', '/api/miner_cancel', {id:id}, function(rs){
						that.loading = false;
						if(!rs.status){
							that.gerror(rs.msg)
							return
						}
						
						// reload page data
						that.getData();
					})
				}).catch(()=>{
					that.loading = false
				})
			},
			getData(){
				// get all records
				let that = this;
				this.greq('get', '/api/miner_orders', false, function(rs){
					if( !rs.status ){
						that.gerror(rs.msg)
						return
					}
					
					that.pendings = [];
					that.dones = [];
					that.cancels = [];
					for(let row of rs.data){
						if(row.status==0){
							that.pendings.push(row)
						} else if(row.status==1){
							that.dones.push(row)
						} else {
							that.cancels.push(row)
						}
					}
				});
			}
		},
		mounted(){
			this.getData();
		}
	}
</script>

<style>
</style>