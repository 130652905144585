<template>
	<div class="recharge_list">
		<div style="position: absolute;top:0;left:0;overflow: hidden;width:100%;z-index:-1;" ref="bg" class="rl_bg">
			<img src="../assets/recharge_list_bg.jpg" alt="" style="height:100%;">
		</div>
		<div class="bg_white">
			<el-row type="flex" align="middle" class="p3">
				<el-col :span="6" class="tl f16">
					<div @click="$router.go(-1)" class="c_black2">
						<i class="fa fa-angle-left f18"></i>
					</div>
				</el-col>
				<el-col :span=12 class="f16">
					{{$t('充值')}}
				</el-col>
				<el-col :span=6 class="tr">
					<router-link to="/recharge_history" class="c_black2 f12">
						<i class="fa fa-file"></i> {{$t('充值明细')}}
					</router-link>
				</el-col>
			</el-row>
		</div>
		
		<div class="bg_white m4 bradius8">
			<div class="p4 tl">
				<div class="fbold pb3">{{$t('请选择充值通道')}}</div>
				<div class="pt3 pb3" style="border-top:1px solid #eee;border-bottom:1px solid #eee;" @click="$router.push('/recharge')">
					<el-row type="flex" align="middle">
						<el-col :span="4">
							<i class="fa fa-credit-card c_white bg_yellow p2 br6 f16"></i>
						</el-col>
						<el-col :span="18">
							{{$t('币种')}}
						</el-col>
						<el-col :span="2" class="tc">
							<i class="fa fa-angle-right f16"></i>
						</el-col>
					</el-row>
				</div>
				
				<div class="mt5 p3 br6 tc fbold f14" style="background-image: linear-gradient(to bottom, #F1E6FB, #BAADF3);color:#6756A9">
					{{$t('火币官方充值通道')}}
				</div>
				<div class="mt3 p3 br6 tc fbold f14" style="background-color: #1D8CFF;color:#fff">
					{{$t('CoinCola官方充值通道')}}
				</div>
				<div class="mt3 p3 br6 tc fbold f14" style="background-image: linear-gradient(to bottom, #AEC9FE, #597FD2);color:#2A458D">
					{{$t('OKEX官方充值通道')}}
				</div>
				<div class="mt3 p3 br6 tc fbold f14" style="background-image: linear-gradient(to bottom, #F7D664, #FBB345);color:#3D3E46">
					{{$t('幣安官方充值通道')}}
				</div>
				<div class="mt3 p3 br6 tc fbold f14" style="background-color:#EE616A;color:#fff">
					{{$t('中幣官方充值通道')}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "RechargeList",
		data(){
			return {
				
			}
		},
		mounted(){
			console.log(window.screen.height);
			document.getElementsByClassName('rl_bg')[0].style.height = window.screen.height+"px";
		}
	}
</script>

<style>
</style>