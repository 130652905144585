<template>
	<div class="ciframe_page" style="position: absolute;height:100%;width:100%;overflow: hidden;">
		<div v-if="show_back">
			<el-row class="m3">
				<el-col :span="4" style="font-size: 20px;text-indent: 10px;" class="c_blue tl">
					<div @click="$router.go(-1)" class="c_blue">
						<i class="fa fa-angle-left" aria-hidden="true"></i>
					</div>
				</el-col>
				<el-col :span="16">{{$t('联系客服')}}</el-col>
				<el-col :span="4">
					<span></span>
				</el-col>
			</el-row>
		</div>
		<iframe :src="url" frameborder="0" class="w100" style="height: 100%;" @load="iframeLoaded"></iframe>
	</div>
</template>

<script>
	export default {
		name: "ClientIframe",
		data(){
			return {
				show_back: false,
				url: '',
			}
		},
		methods: {
			iframeLoaded(){
				let that = this;
				setTimeout(function(){
					that.$parent.loading = false;
				}, 2000);
				
				console.log('tet');
			}
		},
		mounted(){
			this.$parent.loading = true;
			this.$parent.show_footer = false;
			let that = this;
			this.greq('get', '/main/client_service_url', false, function(rs){
				if( rs.status ){
					that.url = rs.data.url;
					that.show_back = rs.data.show_header
				}
			})
		},
		beforeDestroy(){
			this.$parent.loading = false;
			this.$parent.show_footer = true;
		}
	}
</script>

<style>
</style>