<template>
	<div class="coin_page bg_white" v-loading.fullscreen.lock="loading" element-loading-background="rgba(0,0,0,0.4)">
		<div class="p3" style="border-bottom: 1px solid #eee;">
			<el-row class="p2 c_black2" type="flex" align="middle">
				<el-col :span="4">
					<span></span>
				</el-col>
				<el-col :span=16 class="f16">
					{{$t('币币交易')}}
				</el-col>
				<el-col :span=4 class="">
					<span></span>
				</el-col>
			</el-row>
		</div>
		
		<div class="pl4 pr4 pt4 pb2">
			<div>
				<el-row>
					<el-col :span="12" class="tl">
						<div @click="drawer=true">
							{{info.title}}/USDT <i class="fa fa-angle-down pl3"></i>
						</div>
					</el-col>
					<el-col :span="12" class="tr">
						<router-link :to="'/contract/'+id" class="c_blue">
							<i class="fa fa-bar-chart"></i>
						</router-link>
					</el-col>
				</el-row>
				
			</div>
		</div>
		
		<div class="">
			<el-row>
				<el-col :span="14" class="p3">
					<div class="mt3">
						<el-row class="f12">
							<el-col :span="12">
								<div @click="order.type=0">
									<div class="long_btn w100" v-if="order.type==0">{{$t('买入')}}</div>
									<div class="empty_btn w100" v-if="order.type==1">{{$t('买入')}}</div>
								</div>
							</el-col>
							<el-col :span="12">
								<div @click="order.type=1">
									<div class="short_btn w100" v-if="order.type==1">{{$t('卖出')}}</div>
									<div class="empty_btn w100" v-if="order.type==0">{{$t('卖出')}}</div>
								</div>
							</el-col>
						</el-row>
					</div>
					
					<!-- 委托 -->
					<div class="pt2 pb2">
						<el-select v-model="order.price_type" size="mini" class="w100">
							<el-option :label="$t('市价委托')" value="0"></el-option>
							<el-option :label="$t('限价委托')" value="1"></el-option>
						</el-select>
					</div>
					
					<!-- 限价 -->
					<div class="pt2 pb2">
						<el-input class="w100" size="mini" v-model="order.price" :disabled="order.price_type==0"></el-input>
					</div>
					
					<!-- 数量 -->
					<div class="pt3 pb2">
						<el-input size="mini" v-model="order.amount" placeholder="0.000" @input="order.amount=order.amount.replace(/[^\d\.]/g, '')" suffix-icon="btc_tail c_black2" class="w100"></el-input>
					</div>
					
					<!-- 说明 -->
					<div class="f12 tl pl2 c_black2 pb2">
						<div>= {{amount_usdt}} USDT</div>
						<div v-if="order.type==0">{{$t('可用余额')}} {{info.balance_usdt}} USDT</div>
						<div v-if="order.type==1">{{$t('可用余额')}} {{info.balance_btc}} BTC</div>
						<div>{{$t('手续费')}} {{order_fee}} BTC ({{info.c2c_rate}}%)</div>
					</div>
					
					<!-- buy button-->
					<div class="pt2 pb2" @click="submit_order">
						<div class="long_btn f13" v-if="order.type==0">{{$t('买入')}}{{info.title}}</div>
						<div class="short_btn f13" v-if="order.type==1">{{$t('卖出')}}{{info.title}}</div>
					</div>
				</el-col>
				
				<!-- 10档行情 -->
				<el-col :span="10">
					<div class="m3 f12">
						<el-row class="c_gray">
							<el-col :span="12" class="tl">{{$t('价格')}}(USDT)</el-col>
							<el-col :span="12" class="tr">{{$t('数量')}}</el-col>
						</el-row>
				
				
						<div class="mt2">
							<div class="mt1" v-for="item in info.level5_up" v-bind:key="item.id">
								<div class="l10_bar">
									<div class="l10_bg bg_up" :style="{width:item.w+'%'}"></div>
								</div>
								<el-row style="height:18px;line-height:18px;">
									<el-col :span="12" class="tl fbold c_up">{{item.p}}</el-col>
									<el-col :span="12" class="tr c_black2">{{item.v}}</el-col>
								</el-row>
							</div>
						</div>
				
						<div class="pt3 pb3 mt2 mb2 f18 fbold tl"
							style="border-top:1px solid #eee;border-bottom: 1px solid #eee;">
							<span class="">{{info.price}}</span>
						</div>
				
						<div class="mt2">
							<div class="mt1" v-for="item in info.level5_down" v-bind:key="item.id">
								<div class="l10_bar">
									<div class="l10_bg bg_down" :style="{width:item.w+'%'}"></div>
								</div>
								<el-row style="height:18px;line-height:18px;">
									<el-col :span="12" class="tl fbold c_down">{{item.p}}</el-col>
									<el-col :span="12" class="tr c_black2">{{item.v}}</el-col>
								</el-row>
							</div>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
		
		<!-- 当前委托 -->
		<div class="p3" style="border-top:1px solid #eee;">
			<el-row>
				<el-col :span="18" class="tl">
					<span class="p3" style="border-bottom:3px solid #fff" v-if="tab!=0" @click="tab=0">{{$t('当前委托')}}</span>
					<span class="p3" style="border-bottom:3px solid #5670f6" v-if="tab==0">{{$t('当前委托')}}</span>
					<span class="p3" style="border-bottom:3px solid #fff" v-if="tab!=1" @click="tab=1">{{$t('简介')}}</span>
					<span class="p3" style="border-bottom:3px solid #5670f6" v-if="tab==1">{{$t('简介')}}</span>
				</el-col>
				<el-col :span="6" class="tr">
					<router-link to="/coin_history">{{$t('全部')}}</router-link>
				</el-col>
			</el-row>
			
			<!-- 无数据 -->
			<div class="mt5 pb5" v-if="info.orders.length<1 && tab==0">
				<div><img src="../assets/nodata.png" height="50px" alt=""></div>
				<div class="mt2 c_gray f12">{{$t('暂无数据')}}</div>
			</div>
			
			<!-- 有数据 -->
			<div class="mt5 pb5" v-if="info.orders.length>0 && tab==0">
				<div v-for="item in info.orders" v-bind:key="item.id" class="f12 p3" style="border-bottom: 1px solid #eee;">
					<el-row type="flex" align="middle">
						<el-col :span="2" class="tl">
							<span class="bg_up p1 c_white br4 f12" v-if="item.buy_or_sell==0">{{$t('买')}}</span>
							<span class="bg_down p1 c_white br4 f12" v-if="item.buy_or_sell==1">{{$t('卖')}}</span>
						</el-col>
						<el-col :span="22" class="tl  f14">
							{{item.coin}}
						</el-col>
					</el-row>
					<el-row type="flex" align="middle" class="mt3">
						<el-col :span="6" class="tl">
							<div class="c_gray">{{$t('委托数')}}</div>
							<div class="mt2">{{item.amount}}</div>
						</el-col>
						<el-col :span="6" class="tl">
							<div class="c_gray">{{$t('委托价')}}</div>
							<div class="mt2">{{item.price}}</div>
						</el-col>
						<el-col :span="6" class="tl">
							<div class="c_gray">{{$t('当前价')}}</div>
							<div class="mt2">{{info.price}}</div>
						</el-col>
						<el-col :span="6">
							<div class="bg_blue2 br6 c_white pt2 pb2 f12" @click="order_cancel(item.oid)">
								{{$t('撤单')}}</div>
						</el-col>
					</el-row>
				</div>
			</div>
			
			<!-- 简介 -->
			<div class="mt5 pb5 tl" v-if="tab==1" style="text-indent: 2em;line-height:20px;">
				<div>
					Bitcoin is a decentralized cryptocurrency originally described in a 2008 whitepaper by a person, or group of people, using the alias Satoshi Nakamoto. It was launched soon after, in January 2009.
				</div>
				<div>
					Bitcoin is a peer-to-peer online currency, meaning that all transactions happen directly between equal, independent network participants, without the need for any intermediary to permit or facilitate them. Bitcoin was created, according to Nakamoto’s own words, to allow “online payments to be sent directly from one party to another without going through a financial institution.”
				</div>
				<div>
					Some concepts for a similar type of a decentralized electronic currency precede BTC, but Bitcoin holds the distinction of being the first-ever cryptocurrency to come into actual use.
				</div>
			</div>
		</div>
		
		<!-- 弹出层 -->
		<el-drawer title="" :visible.sync="drawer" direction="ltr" :show-close=false size="50%">
			<div class="fbold tl f14 pl3 pb3" style="border-bottom: 1px solid #eee;">{{$t('币币交易')}}</div>
			<div class="p3 f12">
				<div @click="drawer=false;">
					<el-row>
						<el-col :span="12" class="tl">BTC/USDT</el-col>
						<el-col :span="12" class="tr">{{info.price}}</el-col>
					</el-row>
				</div>
			</div>
		</el-drawer>
	</div>
</template>

<script>
	export default {
		name: "CoinView",
		props: ['id'],
		data(){
			return {
				loading: false,
				info: {
					title: "BTC",
					balance_usdt: 0.00,
					balance_btc: 0.00,
					price: 27000,
					c2c_rate: 2.5,
					level5_up: [],
					level5_down: [],
					// orders: [{oid:1, coin:"BTC", buy_or_sell:0, price:28000, amount:0.01, addtime:'2023-01-01 00:00:00'},{oid:1, coin:"BTC", buy_or_sell:1, price:28000, amount:0.01, addtime:'2023-01-01 00:00:00'}],
					orders: [],
				},
				order:{
					type: 0, // 0 buy 1 sell
					price_type: "0", 
					price: "",
					amount: "",
				},
				timer_data: false,
				drawer: false,
				tab: 0,
				
			}
		},
		computed:{
			amount_usdt: function(){
				return this.order.amount * this.order.price;
			},
			order_fee: function(){
				return (this.order.amount * this.info.c2c_rate / 100).toFixed(6);
			}
		},
		methods: {
			prefixSuffix() {
				document.getElementsByClassName('btc_tail')[0].innerHTML = 'BTC';
			},
			submit_order(){
				if( !this.order.amount ){
					this.gerror(this.$t('请输入数量'));
					return
				}
				if( !this.order.price ){
					this.gerror(this.$t('请输入价格'));
					return
				}
				
				// 买入
				if( this.order.type == 0 ){
					// post data: pid, type, price_type, price, amount
					let that = this;
					let postdata = {
						pid : this.id,
						type: this.order.type,
						price_type: this.order.price_type,
						price: this.order.price,
						amount: this.order.amount,
					};
					that.greq('post', '/api/coin_buy', postdata, function(rs){
						if( rs.status ){
							that.$message(rs.msg);
						} else {
							that.gerror(rs.msg);
						}
					})
				} else {
					// 售出
					// post data: pid, price_type, price, amount
					let that = this;
					let postdata = {
						pid: this.id,
						price_type: this.order.price_type,
						price: this.order.price,
						amount: this.order.amount,
					}
					that.greq('post', '/api/coin_sell', postdata, function(rs){
						if( rs.status ){
							that.$message(rs.msg);
						} else {
							that.gerror(rs.msg);
						}
					})
				}
			},
			getData(){
				let that = this;
				that.greq('post', '/main/coin_lvs', {pid:that.id}, function(rs){
					if( rs.status ){
						that.info = rs.data;
						if(that.order.price_type == 0){
							that.order.price = that.info.price;
						}
					} else {
						that.gerror(rs.msg);
					}
					if(!that._isDestroyed){
						that.timer_data = setTimeout(function(){
							that.getData();
						}, 2000);
					}
				})
			},
			order_cancel(oid){
				let that = this;
				that.greq('post', '/api/coin_cancel', {oid:oid}, function(rs){
					if( rs.status ){
						// 清空委托数据，等待下次更新
						that.$message(rs.msg);
						
						that.info.orders = [];
					} else {
						that.gerror(rs.msg);
					}
				})
			}
		},
		mounted(){
			this.prefixSuffix();
			this.getData();
		},
		beforeDestroy(){
			if( this.timer_data ){
				clearTimeout(this.timer_data);
			}
		}
	}
</script>

<style>
	.coin_page .long_btn {
		padding: 8px 0px;
		background-color: #1cad90;
		border-radius: 6px;
		color: #fff;
	}
	
	.coin_page .empty_btn {
		padding: 8px 0px;
		border-radius: 6px;
		color: #8b9eac;
	}
	
	.coin_page .short_btn {
		padding: 8px 0px;
		background-color: #cd4e65;
		border-radius: 6px;
		color: #fff;
	}
	
	.coin_page .btc_tail{
		font-style: normal;
	}
	
	.coin_page .l10_bg {
		height: 18px;
		opacity: 0.4;
		position: absolute;
		right: 0px;
	}
	
	.coin_page .l10_bar {
		position: relative;
		height: 1px;
		width: 100%;
	}
</style>