<template>
	<div class="pwd_page">
		<div class="bg_white">
			<el-row class="p2 c_gray" type="flex" align="middle">
				<el-col :span=4 class="c_blue tc" style="font-size:30px;">
					<router-link to="/" class="c_blue">
						<i class="fa fa-angle-left" aria-hidden="true"></i>
					</router-link>
				</el-col>
				<el-col :span=16 class="f16">
					{{$t("修改密码")}}
				</el-col>
				<el-col :span=4>&nbsp;</el-col>
			</el-row>
		</div>

		<div class="m5 tl">
			<div class="">{{$t('旧密码')}}</div>
			<div class="pt2">
				<el-input v-model="data.old_pwd" type="password" size="small" :placeholder="$t('请输入旧密码')"></el-input>
			</div>

			<div class="pt3">{{$t('新密码')}}</div>
			<div class="pt2">
				<el-input v-model="data.new_pwd" type="password" size="small" :placeholder="$t('请输入新密码')"></el-input>
			</div>

			<div class="pt3">{{$t('重复新密码')}}</div>
			<div class="pt2">
				<el-input v-model="data.re_new_pwd" type="password" size="small" :placeholder="$t('请输入重复新密码')"></el-input>
			</div>
		</div>

		<div class="tc">
			<el-button round class="w80" type="primary" @click="submit">{{$t('提交')}}</el-button>
		</div>

	</div>
</template>

<script>
	export default {
		name: "PasswordView",
		data() {
			return {
				data: {
					old_pwd: '',
					new_pwd: '',
					re_new_pwd: '',
				}
			}
		},
		methods: {
			submit(){
				if( !this.data.old_pwd ){
					this.gerror(this.$t('请输入旧密码'));
					return
				}
				if( !this.data.new_pwd ){
					this.gerror(this.$t('请输入新密码'));
					return
				}
				if( !this.data.re_new_pwd ){
					this.gerror(this.$t('请输入重复新密码'));
					return
				}
				if( this.data.new_pwd != this.data.re_new_pwd ){
					this.gerror(this.$t('两次输入密码不一致'));
					return
				}

				let that = this;
				this.$parent.loading = true;
				that.greq('post', '/api/password', that.data, function(rs){
					that.$parent.loading = false;
					if( rs.status ){
						that.$message({message:rs.msg});
					} else {
						that.gerror(rs.msg);
					}
				}, function(){
					that.$parent.loading = false;
				});
			}
		},
	}
</script>

<style>

</style>
