<template>
	<div class="marketsidebar">
		<el-drawer :title="$t('')" :visible.sync="drawer" direction="ltr" :show-close=false size="50%">
			<div class="fbold tl f16 pl3 pb2">{{$t('市场')}}</div>
			<div class="w100" style="overflow: scroll;">
				<div style="max-width:1000px;">
					<table border="0">
						<tr>
							<td v-for="item in tabs" v-bind:key="item.id">
								<div class="trade_nav_item">
									<div :class="{'tni_active':drawer_tab==item.value}" @click="drawer_tab=item.value">
										{{item.name}}
									</div>
								</div>
							</td>
		
						</tr>
					</table>
		
				</div>
			</div>
		
			<div>
				<el-tabs v-model="drawer_tab">
					<el-tab-pane :lazy=true label="" :name="row.value" v-for="row in tabs" v-bind:key="row.id">
						<div v-for="item in currentData" v-bind:key="item.id" @click="itemCallback(item.id)">
							<el-row class="p3 f13">
								<el-col :span="12" class="tl fbold">{{item.title}}</el-col>
								<el-col :span="12" class="tr">
									<span class="c_up" v-if="item.change>=0">{{item.price}}</span>
									<span class="c_down" v-if="item.change<0">{{item.price}}</span>
								</el-col>
							</el-row>
						</div>
					</el-tab-pane>
				</el-tabs>
			</div>
		</el-drawer>
	</div>
</template>

<script>
	export default {
		name: "MarketSiderBar",
		props: {
			itemCallback: {
				type: Function,
				default: function(){}
			}
		},
		data(){
			return {
				drawer: false,
				drawer_tab: "1",
				tabs: [{
						name: this.$t('关注'),
						value: '4'
					},
					{
						name: this.$t('国际期货'),
						value: '0'
					},
					{
						name: this.$t('加密货币'),
						value: '1'
					},
					{
						name: this.$t('外汇'),
						value: '2'
					},
					{
						name: this.$t('贵金属'),
						value: '3'
					},
				],
				drawer_timer: false,
				products: [],
				currentData: [],
			}
		},
		watch: {
			drawer: function(val) {
				// 如果是刚打开的话，开始更新数据
				let that = this;
				if (val) {
					if (this.products.length < 1) {
						this.getMarketData();
					} else {
						this.drawer_timer = setTimeout(function() {
							that.getMarketData();
						}, 2000);
					}
				} else {
					// 如果是关闭的话，停止更新数据
					if (this.drawer_timer) {
						clearTimeout(this.drawer_timer)
						this.drawer_timer = false;
					}
				}
			},
			
			drawer_tab: function(val) {
				console.log(val);
				this.currentData = this.filterData(val);
			},
		},
		methods: {
			getMarketData() {
				let that = this;
				this.greq('get', '/main/products', false, function(rs) {
					that.products = rs.data;
					that.currentData = that.filterData(that.drawer_tab);
			
					if(!that._isDestroyed){
						that.drawer_timer = setTimeout(function() {
							that.getMarketData();
						}, 2000);
					}
				});
			},
			filterData(cat) {
				return this.products.filter(function(item) {
					return item.category == cat
				})
			},
		},
		beforeDestroy(){
			if (this.drawer_timer) {
				clearTimeout(this.drawer_timer)
			}
		}
	}
</script>

<style>
	.marketsidebar .trade_nav_item {
		padding: 0px 10px;
		word-break: keep-all;
	}
	
	.marketsidebar .trade_nav_item div {
		padding: 4px 0px;
	}
	
	.marketsidebar .trade_nav_item .tni_active {
		color: #409EFF;
		border-bottom: 2px solid #409EFF;
	}
	
	.marketsidebar .el-tabs__header {
		display: none;
	}
	
	.marketsidebar .el-dialog__body{
		padding-top:0px !important;
	}
</style>