<template>
	<div class="focus_page">
		<div class="bg_white">
			<el-row class="p2 c_black2" type="flex" align="middle">
				<el-col :span=4 class="c_blue tc" style="font-size:30px;">
					<router-link to="/market" class="c_black">
						<i class="fa fa-angle-left" aria-hidden="true"></i>
					</router-link>
				</el-col>
				<el-col :span=16 class="f16">
					{{$t('添加自选')}}
				</el-col>
				<el-col :span=4 class="">
					<span></span>
				</el-col>
			</el-row>
		</div>
		
		<div class="m3">
			<el-tabs v-model="tabCat" :stretch=true class="">
				<el-tab-pane :label="row.name" :lazy=true :name="row.value" v-for="row in tabs" v-bind:key="row.id">
					<div>
						<div v-for="item in currentData" v-bind:key="item.id" class="bg_white m3 p3 bradius8 bshadow" @click="toggleFocus(item.id)">
							<el-row type="flex" align="middle">
								<el-col :span="3">
									<i class="fa fa-circle-o c_gray f18" v-if="!focus[item.id]"></i>
									<i class="fa fa-check-circle-o c_blue f18" v-if="focus[item.id]"></i>
								</el-col>
								<el-col :span="4">
									<img :src="item.logo" alt="" style="border-radius: 15px;display: block;width:30px;height:30px;">
								</el-col>
								<el-col :span="16" class="tl fbold">
									{{item.title}}
								</el-col>
							</el-row>
						</div>
					</div>
				</el-tab-pane>
			</el-tabs>
		</div>
		
		<div style="position: fixed;width:100%;bottom:0px;border-top:1px solid #eee;" class="bg_white p3">
			<el-button class="w80" type="primary" round @click="submit">{{$t('提交')}}</el-button>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'FocusAdd',
		data(){
			return {
				tabCat: "0",
				tabs: [
					{name:this.$t('国际期货'),value:"0",show:true},
					{name:this.$t('加密货币'),value:"1",show:true},
					{name:this.$t('外汇'),value:"2",show:true},
					{name:this.$t('贵金属'),value:"3",show:true},
				],
				products: [],
				currentData: [],
				focus: {},
			}
		},
		watch:{
			tabCat: function(val){
				this.currentData = this.filterData(val);
			},
		},
		methods: {
			initData(){
				let that = this;
				this.greq('get', '/main/products', false, function(rs){
					that.products = rs.data;
					that.currentData = that.filterData(that.tabCat);
					
					that.getFocus();
				});
			},
			filterData(cat){
				return this.products.filter(function(item){
					return item.category == cat
				})
			},
			getFocus(){
				let that = this;
				this.greq('get', '/api/focus', false, function(rs){
					if( rs.status ){
						// that.focus = rs.data
						let focus = rs.data;
						let tmp = {};
						for(let item of that.products){
							if( item.category < 4 ){
								if( focus.indexOf(item.id) > -1 ){
									tmp[item.id] = true;
								}else{
									tmp[item.id] = false;
								}
							}
						}
						console.log(tmp);
						that.focus = tmp;
					}
				})
			},
			toggleFocus(pid){
				this.focus[pid] = !this.focus[pid];
			},
			
			submit(){
				let focus = [];
				for(let index in this.focus){
					if(this.focus[index]){
						focus.push(index)
					}
				}
				
				let that = this;
				this.greq('post', '/api/focus_add', {pids:focus}, function(rs){
					if( rs.status ){
						that.$message(rs.msg);
					}
				})
			}
		},
		mounted(){
			this.$parent.show_footer = false;
			this.initData();
			
		},
		beforeDestroy(){
			this.$parent.show_footer = true;
		}
	}
</script>

<style>
</style>