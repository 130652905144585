<template>
	<div class="rh_page">
		<div class="borderb bg_white p3">
			<el-row type="flex" align="middle" class="p2">
				<el-col :span="6" class="tl f16">
					<div @click="$router.push('/recharge_list')" class="c_black2">
						<i class="fa fa-angle-left f18"></i>
					</div>
				</el-col>
				<el-col :span=12 class="f16">
					{{$t('充值明细')}}
				</el-col>
				<el-col :span=6 class="tr">
					<span></span>
				</el-col>
			</el-row>
		</div>
		
		<div class="mt4 m3 p3 bg_white bradius8">
			<div v-if="list.length>0">
				<el-row class="c_gray">
					<el-col :span="6">{{$t('支付金额')}}</el-col>
					<el-col :span="12">{{$t('支付时间')}}</el-col>
					<el-col :span="6">{{$t('充值状态')}}</el-col>
				</el-row>
				
				<div v-for="item in list" v-bind:key="item.id" class=" pt3">
					<el-row>
						<el-col :span="6">${{item.amount}}</el-col>
						<el-col :span="12">{{item.t}}</el-col>
						<el-col :span="6">{{item.status}}</el-col>
					</el-row>
				</div>
			</div>
			
			<div v-if="list.length==0">
				<div class="mt5 pb5">
					<div><img src="../assets/nodata.png" height="50px" alt=""></div>
					<div class="mt2 c_gray f12">{{$t('暂无数据')}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "RechargeHistory",
		data(){
			return {
				list: [],
			}
		},
		methods:{
			getData(){
				let that = this;
				this.$parent.loading = true;
				this.greq('get', '/api/recharge_history', false, function(rs){
					that.$parent.loading = false;
					if( rs.status ){
						that.list = rs.data;
					}else {
						that.gerror(rs.msg);
					}
				}, function(){
					that.$parent.loading = false;
				});
			}
		},
		mounted(){
			this.getData();
		},
		beforeCreate(){
			if( !this.glogin() ){
				this.$router.push('/login');
			}
			return false;
		},
	}
</script>

<style>
</style>