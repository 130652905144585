<template>
	<div class="trade_page" v-loading.fullscreen.lock="loading" element-loading-background="rgba(0,0,0,0.4)">
		<div class="tp_head pb2 bg_white" style="top:0px;width: 100%;border-bottom: 1px solid #f3f3f3;z-index: 999;height:170px;">

			<el-dialog :title="info.title" :visible.sync="showOrderTimerUI" :close-on-click-modal=false width="90%" top="30vh">
				<div v-if="showOrderTimer">
					<div style="height:1px;position:relative;">
						<circle-progress v-if="showOrderTimer" class="progress" key="animation-model" :isAnimation=false
							:totalTime="micro_time" :autoRun=true :isRound="true" width="120" radius="10" :progress="order.progress" barColor="#007aff" duration="0" delay="20" backgroundColor="#ddd" :countDownCallBack="order.callback" :secondCallback="order.secondCallback">
						</circle-progress>
					</div>
				</div>
			</el-dialog>
		</div>

		<div>
			{{content}}
		</div>
			
	</div>
</template>

<script>

	import CircleProgress from '../components/circle-progress'

	export default {
		name: 'TestView',
		components: {
			CircleProgress
		},
		data() {
			return {
				showOrderTimer: false,
				showOrderTimerUI: false,
				
				micro_time: 30, // 订单总秒数
				order: {
					progress: 50, // ???
					// total_t: 10, // use micro_time
					current_t: 0,
					callback: this.timerDonwCallback,
					secondCallback: this.timerSecondCallback,
					result: 0,
					oid: 0,
					resultTimer: false,
					buyprice: 0.00,
				},
				info: {
					'c': 0,
					'o': 0,
					'h': 0,
					'l': 0,
					'vol': 0,
					'change': '0.00',
					'title': '...',
					'desc': '...',
					micro_contract: [],
					micro_fee: 0.00,
					userinfo: {
						money: 0.00,
					},
				},
				content: ''
			}
		},
		
		methods: {

			timerDonwCallback(){
				console.log('time_finish...');
				this.showOrderTimer = false;
				this.showOrderLoading = true;

			},
			timerSecondCallback(sec){
				console.log('timer_callback:'+sec)
				this.order.current_t=this.micro_time-sec;
			},
			
		},
		mounted() {
			this.showOrderTimerUI = true;
			this.showOrderTimer = true;

			
		},
		beforeDestroy(){

		},
		destroyed() {

		}
	}
</script>

<style>
	.el-radio-button__orig-radio:checked+.el-radio-button__inner {
		box-shadow: none;
	}

	.trade_page .trade_nav_item {
		padding: 0px 10px;
		word-break: keep-all;
	}

	.trade_page .trade_nav_item div {
		padding: 4px 0px;
	}

	.trade_page .trade_nav_item .tni_active {
		color: #409EFF;
		border-bottom: 2px solid #409EFF;
	}

	.trade_page .el-tabs__header {
		display: none;
	}
	
	.trade_page .el-dialog__body{
		padding-top:0px !important;
	}
</style>
