<template>
	<div class="score_page">
		<div class="bg_white">
			<el-row class="p2 c_black" type="flex" align="middle">
				<el-col :span=4 class="c_blue tc" style="font-size:30px;">
					<router-link to="/" class="c_black">
						<i class="fa fa-angle-left" aria-hidden="true"></i>
					</router-link>
				</el-col>
				<el-col :span=16 class="f16 c_black2">
					{{$t("信用分")}}
				</el-col>
				<el-col :span=4 class="">
					<span></span>
				</el-col>
			</el-row>
		</div>
		
		<div class="mt5">
			<div style="height:1px;width:100%;">
				<div style="width: 280px;height: 280px;margin: 0px auto;display: flex;align-content: space-around;flex-wrap: nowrap;flex-direction: column;justify-content: center;">
					<div class="f16">{{$t('信用分')}}</div>
					<div class="mt3 f50 c_blue2 fbold">{{score}}</div>
					<div class="mt2 fbold">{{grade}}</div>
					<div class="mt2 f13 c_gray">{{$t('评估时间')}}</div>
					<div class="mt2 f13">{{t}}</div>
				</div>
			</div>
			<circle-progress v-if="showProgress" class="progress" key="animation-model" :isAnimation=false
				:isRound="false" width="280" radius="20" :progress="score" barColor="#007aff" duration="0" delay="20" backgroundColor="#ddd">
			</circle-progress>
		</div>
	</div>
</template>

<script>
	import CircleProgress from '../components/circle-progress'

	export default {
		name: "ScoreView",
		components: {
			CircleProgress
		},
		data(){
			return {
				score: 0,
				showProgress: false,
			}
		},
		computed: {
			grade: function(){
				if( this.score >= 90 ){
					return 'A';
				} else if( this.score >= 70 ){
					return 'B';
				} else if( this.score >= 50 ){
					return 'C';
				} else {
					return 'D';
				}
			},
			t: function(){
				let now = new Date();
				let year = now.getFullYear();
				let month = String(now.getMonth() + 1).padStart(2, '0');
				let day = String(now.getDate()).padStart(2, '0');
				
				return `${year}-${month}-${day}`;
			}
		},
		methods: {
			getData(){
				let that = this;
				that.$parent.loading = true;
				this.greq('get', '/api/userinfo', false, function(rs){
					that.$parent.loading = false;
					if( rs.status ){
						that.score = rs.data.score;
						that.showProgress = true;
					}
				}, function(){
					that.$parent.loading = false;
				});
			}
		},
		mounted(){
			this.getData();
		}
	}
</script>

<style>
</style>