<template>
	<div class="chistory_page">
		<div class="bg_white w100 c_black2 bshadow" style="position: fixed;top:0px;border-bottom: 1px solid #eee;z-index: 99">
			<el-row type="flex" align="middle" class="p3">
				<el-col :span="4">
					<router-link to="/coin/12" class="c_black2">
						<i class="fa fa-angle-left f18"></i>
					</router-link>
				</el-col>
				<el-col :span=16 class="f16">
					{{$t('历史委托')}}
				</el-col>
				<el-col :span=4 class="">
					<div @click="show_filter=!show_filter">
						<i class="fa fa-filter f18"></i>
					</div>					
				</el-col>
			</el-row>
			
			<div v-if="show_filter">
				<div class="p3 mt3">
					<div class="tl pb3 c_gray">{{$t('订单类型')}}</div>
					<el-row>
						<el-col :span="8">
							<div class="w80 bg_gray br6 p2" v-if="filter_type!=0" @click="filter_type=0">{{$t('买入')}}</div>
							<div class="w80 bg_blue c_white br6 p2" v-if="filter_type==0">{{$t('买入')}}</div>
						</el-col>
						<el-col :span="8">
							<div class="w80 bg_gray br6 p2" v-if="filter_type!=1" @click="filter_type=1">{{$t('卖出')}}</div>
							<div class="w80 bg_blue c_white br6 p2" v-if="filter_type==1">{{$t('卖出')}}</div>
						</el-col>
					</el-row>
					
					<div class="tl pb3 pt3 c_gray" v-if="tab=='1'">{{$t('订单状态')}}</div>
					<el-row v-if="tab=='1'">
						<el-col :span="8">
							<div class="w80 bg_gray br6 p2" v-if="filter_status!=0" @click="filter_status=0">{{$t('已成交')}}</div>
							<div class="w80 bg_blue c_white br6 p2" v-if="filter_status==0">{{$t('已成交')}}</div>
						</el-col>
						<el-col :span="8">
							<div class="w80 bg_gray br6 p2" v-if="filter_status!=1" @click="filter_status=1">{{$t('已取消')}}</div>
							<div class="w80 bg_blue c_white br6 p2" v-if="filter_status==1">{{$t('已取消')}}</div>
						</el-col>
					</el-row>
				</div>
				<div class="pt3">
					<el-row style="border-top:1px solid #eee;">
						<el-col :span="12">
							<div class="pt3 pb3" @click="filter_status=2;filter_type=2;">{{$t('重置')}}</div>
						</el-col>
						<el-col :span="12" class="bg_blue">
							<div class="pt3 pb3 c_white" @click="type=filter_type;status=filter_status;show_filter=false">{{$t('确定')}}</div>
						</el-col>
					</el-row>
				</div>
			</div>
		</div>
		
		<div class="p4" style="padding-top:45px;">
			<el-tabs :stretch=true v-model="tab">
				<el-tab-pane :label="$t('当前委托')" name="0">
					<div v-for="item in waitingOrders" v-bind:key="item.id" class="f12 p3" style="border-bottom: 1px solid #eee;">
						<el-row type="flex" align="middle">
							<el-col :span="2" class="tl">
								<span class="bg_up p1 c_white br4 f12" v-if="item.buy_or_sell==0">{{$t('买')}}</span>
								<span class="bg_down p1 c_white br4 f12" v-if="item.buy_or_sell==1">{{$t('卖')}}</span>
							</el-col>
							<el-col :span="22" class="tl  f14">
								{{item.coin}}
							</el-col>
						</el-row>
						<el-row type="flex" align="middle" class="mt3">
							<el-col :span="6" class="tl">
								<div class="c_gray">{{$t('委托数')}}</div>
								<div class="mt2">{{item.amount}}</div>
							</el-col>
							<el-col :span="6" class="tl">
								<div class="c_gray">{{$t('委托价')}}</div>
								<div class="mt2">{{item.price}}</div>
							</el-col>
							<el-col :span="6" class="tl">
								<div class="c_gray">{{$t('当前价')}}</div>
								<div class="mt2">{{price}}</div>
							</el-col>
							<el-col :span="6">
								<div class="bg_blue2 br6 c_white pt2 pb2 f12" @click="order_cancel(item.oid)">
									{{$t('撤单')}}</div>
							</el-col>
						</el-row>
					</div>
					
					<div class="mt5 pb5" v-if="waitingOrders.length==0">
						<div><img src="../assets/nodata.png" height="50px" alt=""></div>
						<div class="mt2 c_gray f12">{{$t('暂无数据')}}</div>
					</div>
				</el-tab-pane>
				
				<el-tab-pane :label="$t('历史委托')" name="1">
					<div v-for="item in doneOrders" v-bind:key="item.id" class="f12 p3" style="border-bottom: 1px solid #eee;">
						<el-row type="flex" align="middle">
							<el-col :span="2" class="tl">
								<span class="bg_up p1 c_white br4 f12" v-if="item.buy_or_sell==0">{{$t('买')}}</span>
								<span class="bg_down p1 c_white br4 f12" v-if="item.buy_or_sell==1">{{$t('卖')}}</span>
							</el-col>
							<el-col :span="22" class="tl  f14">
								{{item.coin}}
							</el-col>
						</el-row>
						<el-row type="flex" align="middle" class="mt3">
							<el-col :span="8" class="tl">
								<div class="c_gray">{{$t('委托数')}}</div>
								<div class="mt2">{{item.amount}}</div>
							</el-col>
							<el-col :span="8" class="tl">
								<div class="c_gray">{{$t('委托价')}}</div>
								<div class="mt2">{{item.price}}</div>
							</el-col>
							<el-col :span="8" class="tl">
								<div class="c_gray">{{$t('状态')}}</div>
								<div class="mt2" v-if="item.status==1">{{$t('已完成')}}</div>
								<div class="mt2" v-if="item.status==2">{{$t('已取消')}}</div>
							</el-col>
						</el-row>
					</div>
					
					<div class="mt5 pb5" v-if="doneOrders.length==0">
						<div><img src="../assets/nodata.png" height="50px" alt=""></div>
						<div class="mt2 c_gray f12">{{$t('暂无数据')}}</div>
					</div>
				</el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>

<script>
	export default {
		name: "CoinHistory",
		data(){
			return {
				show_filter: false,
				filter_type: 2, //0 buy, 1 sell, 2 all
				filter_status: 2, //0 deal, 1 cancel, 2 all
				type: 2,
				status: 2,
				tab: "0",
				price: 27000,
				orders: [{oid:1, coin:"BTC", buy_or_sell:0, price:28000, amount:0.01, addtime:'2023-01-01 00:00:00'},{oid:1, coin:"BTC", buy_or_sell:1, price:28000, amount:0.01, addtime:'2023-01-01 00:00:00'}],
				timer_data: false,
			}
		},
		computed: {
			waitingOrders: function(){
				let that = this;
				return this.orders.filter(function(a){
					if( that.type == 2 ){
						return a.status==0;
					} else {
						return a.status==0 && a.buy_or_sell==that.type;
					}
					
				})
			},
			doneOrders: function(){
				let typeArr = this.type == 2 ? [0,1] : [this.type];
				let statusArr = this.status == 2 ? [1,2] : [this.status+1];
				console.log('debug');
				console.log(typeArr);
				console.log(statusArr);
				return this.orders.filter(function(a){
					console.log(a);
					return typeArr.indexOf(parseInt(a.buy_or_sell))>-1 && statusArr.indexOf(parseInt(a.status))>-1;
					
				})
			}
		},
		methods: {
			getData: function(){
				let that = this;
				that.greq('get', '/api/coin_orders', false, function(rs){
					if(rs.status){
						that.orders = rs.data.orders;
						that.price = rs.data.latest.c;
					} else {
						that.gerror(rs.msg);
					}
					
					if(!that._isDestroyed){
						that.timer_data = setTimeout(function(){
							that.getData();
						}, 3000);
					}
				})
			},
			order_cancel(oid){
				let that = this;
				that.greq('post', '/api/coin_cancel', {oid:oid}, function(rs){
					if( rs.status ){
						// 清空委托数据，等待下次更新
						that.$message(rs.msg);
						
						that.orders = [];
					} else {
						that.gerror(rs.msg);
					}
				})
			}
		},
		mounted(){
			this.getData();
		},
		beforeDestroy(){
			if(this.timer_data){
				clearTimeout(this.timer_data);
			}
		}
	}
</script>

<style>
</style>