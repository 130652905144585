import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Home.vue'
import SearchPage from '../views/SearchPage.vue'
import LoginView from '../views/Login.vue'
import RegisterView from '../views/Register.vue'
import LangView from '../views/Lang.vue'
import ContentView from '../views/Content.vue'
import BankView from '../views/Bank.vue'
import PersonalView from '../views/Personal.vue'
import HelpView from '../views/Help.vue'
import SafeView from '../views/Safe.vue'
import MinerView from '../views/Miner.vue'
import MinerBuy from '../views/MinerBuy.vue'
import MinerOrders from '../views/MinerOrders.vue'
import MarketView from '../views/Market.vue'
import TradeView from '../views/Trade.vue'
import FocusAdd from '../views/FocusAdd.vue'
import ContractView from '../views/Contract.vue'
import ToView from '../views/To.vue'
import ContractHistory from '../views/ContractHistory.vue'
import CoinView from '../views/Coin.vue'
import CoinHistory from '../views/CoinHistory.vue'
import TradeHistory from '../views/TradeHistory.vue'
import AssetView from '../views/Asset.vue'
import RechargeList from '../views/RechargeList.vue'
import RechargeView from '../views/Recharge.vue'
import RechargeHistory from '../views/RechargeHistory.vue'
import WithdrawView from '../views/Withdraw.vue'
import WithdrawHistory from '../views/WithdrawHistory.vue'
import ContentType from '../views/ContentType.vue'
import ScoreView from '../views/Score.vue'
import ClientService from '../views/ClientService.vue'
import ForgotView from '../views/Forgot.vue'
import ClientIframe from '../views/ClientIframe.vue'
import AlertsView from '../views/Alerts.vue'
import AlertDetail from '../views/AlertDetail.vue'
import PasswordView from '../views/Password.vue'
import ExchangeView from '../views/Exchange.vue'
import TestView from '../views/Test.vue'
import EarnView from '../views/Earn.vue'
import EarnDetail from '../views/EarnDetail.vue'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'home',
		component: HomeView
	},
	{
		path: '/about',
		name: 'about',
		component: () => import( /* webpackChunkName: "about" */ '../views/AboutView.vue')
	},
	{
		path: '/search',
		name: 'search',
		component: SearchPage
	},
	{
		path: '/login',
		name: 'login',
		component: LoginView
	},
	{
		path: '/register',
		name: 'register',
		component: RegisterView
	},
	{
		path: '/language',
		component: LangView
	},
	{
		path: '/content/:id',
		component: ContentView,
		props: true
	},
	{
		path: '/bank',
		component: BankView
	},
	{
		path: '/personal',
		component: PersonalView
	},
	{
		path: '/help',
		component: HelpView
	},
	{
		path: '/safe',
		component: SafeView
	},
	{
		path: '/miner',
		component: MinerView
	},
	{
		path: '/miner_buy/:id',
		component: MinerBuy,
		props: true
	},
	{
		path: '/miner_orders',
		component: MinerOrders
	},
	{
		path: '/market',
		name: 'market',
		component: MarketView
	},
	{
		path: '/trade/:id/:from?',
		name: 'trade',
		component: TradeView,
		props: true
	},
	{
		path: '/focus_add',
		component: FocusAdd,
	},
	{
		path: '/contract/:id/:type?',
		name: 'contract',
		component: ContractView,
		props: true
	},
	{
		path: '/to/:type/:id',
		component: ToView,
		props: true
	},
	{
		path: '/contract_history',
		component: ContractHistory
	},
	{
		path: '/coin/:id',
		name: 'coin',
		component: CoinView,
		props: true
	},
	{
		path: '/coin_history',
		component: CoinHistory
	},
	{
		path: '/trade_history',
		component: TradeHistory
	},
	{
		path: '/asset',
		name: 'asset',
		component: AssetView,
	},
	{
		path: '/recharge_list',
		component: RechargeList,
	},
	{
		path: '/recharge/:default_type?',
		component: RechargeView,
		props: true
	},
	{
		path: '/recharge_history',
		component: RechargeHistory
	},
	{
		path: '/withdraw',
		component: WithdrawView,
	},
	{
		path: '/withdraw_history',
		component: WithdrawHistory,
	},
	{
		path: '/content_type/:type',
		component: ContentType,
		props: true
	},
	{
		path: '/score',
		component: ScoreView,
	},
	{
		path: '/client_service',
		component: ClientService
	},
	{
		path: '/forgot',
		component: ForgotView,
	},
	{
		path: '/client_iframe',
		component: ClientIframe,
	},
	{
		path: '/alerts',
		component: AlertsView,
	},
	{
		path: '/alert_detail/:id',
		component: AlertDetail,
		props: true,
	},
	{
		path: '/password',
		component: PasswordView,
	},
	{
		path: '/exchange',
		component: ExchangeView,
	},
	{
		path: '/test',
		component: TestView,
	},
	{
		path: '/earn/:type?',
		component: EarnView,
		props: true
	},
	{
		path: '/earn_detail/:id',
		component: EarnDetail,
		props: true
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
