<template>
	<div class="alerts_page">
		<div class="bg_white ">
			<el-row class="p2 c_gray" type="flex" align="middle">
				<el-col :span=4 class="tc" style="font-size:30px;">
					<router-link to="/alerts" class="c_gray">
						<i class="fa fa-angle-left" aria-hidden="true"></i>
					</router-link>
				</el-col>
				<el-col :span=16 class="f16">
					<span></span>
				</el-col>
				<el-col :span="4">&nbsp;</el-col>
			</el-row>
		</div>
		
		<div class="p5 bg_white">
			<div class="borderb pb4">
				<div class="f18 tl">{{data.title}}</div>
			</div>
			<div class="pt4 tl">
				{{data.content}}
			</div>
			<div class="mt4 c_gray tr f13">{{data.add_time}}</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "AlertDetail",
		data(){
			return {
				data: {},
			}
		},
		props: ["id"],
		mounted(){
			// load data
			let that = this;
			this.$parent.loading = true;
			this.greq('post', '/api/alert_detail', {id:this.id}, function(rs){
				that.$parent.loading = false;
				if( rs.status ){
					that.data = rs.data
				} else {
					that.gerror(rs.msg)
				}
			}, function(){
				that.$parent.loading = false;
			})
		}
	}
</script>

<style>
</style>