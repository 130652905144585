<template>
	<div class="thistory_page bg_white">
		<div class="p3" style="border-bottom: 1px solid #eee;">
			<el-row class="p2 c_black2" type="flex" align="middle">
				<el-col :span="4">
					<div @click="$router.go(-1)">
						<i class="fa fa-angle-left f18"></i>
					</div>
				</el-col>
				<el-col :span=16 class="f16">
					{{$t('持仓')}}
				</el-col>
				<el-col :span=4 class="">
					<span></span>
				</el-col>
			</el-row>
		</div>
		
		<div class="pt5 pb5" v-if="orders.length==0">
			<div><img src="../assets/nodata.png" height="50px" alt=""></div>
			<div class="mt2 c_gray f12">{{$t('暂无数据')}}</div>
		</div>
		
		<div class="mt3">
			<div class="p3" v-for="item in orders" v-bind:key="item.id" style="border-bottom: 1px solid #ddd;">
				<el-row>
					<el-col :span="3">
						<span class="bg_up p1 c_white br4 f12" v-if="item.type==0">{{$t('多')}}</span>
						<span class="bg_down p1 c_white br4 f12" v-if="item.type==1">{{$t('空')}}</span>
					</el-col>
					<el-col :span="21" class="tl">
						<span class="fbold">{{item.coinname}}</span>
						<span class="f12 pl3 c_gray">( {{item.addtime}} )</span>
					</el-col>
				</el-row>
			
				<el-row type="flex" align="middle" class="f12 p2 mt2">
					<el-col :span="6" class="tl">
						<div>
							<div class="c_gray">{{$t('数量')}}</div>
							<div class=" mt2">{{item.num}} USDT</div>
						</div>
					</el-col>
					<el-col :span="6" class="">
						<div>
							<div class="c_gray">{{$t('成交价')}}</div>
							<div class=" mt2">{{item.buyprice}}</div>
						</div>
					</el-col>
					<el-col :span="6" class="" v-if="item.status==1">
						<div>
							<div class="c_gray">{{$t('当前价')}}</div>
							<div class=" mt2">{{item.price}}</div>
						</div>
					</el-col>
					<el-col :span="6" class="" v-if="item.status==2">
						<div>
							<div class="c_gray">{{$t('结算价')}}</div>
							<div class=" mt2">{{item.sellprice}}</div>
						</div>
					</el-col>
					<el-col :span="6" class="tr" v-if="item.status==1">
						<div>
							<div class="c_gray">{{$t('倒计时')}}</div>
							<div class=" mt2">{{item.timeout}}s</div>
						</div>
					</el-col>
					<el-col :span="6" class="tr" v-if="item.status==2">
						<div>
							<div class="c_gray">{{$t('盈亏')}}</div>
							<div class=" mt2" v-if="item.ploss==0">{{item.ploss}}</div>
							<div class=" mt2 c_up" v-if="item.ploss>0">{{item.ploss}}</div>
							<div class=" mt2 c_down" v-if="item.ploss<0">{{item.ploss}}</div>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "TradeHistory",
		data(){
			return {
				orders:[],
				timer_data: false,
			}
		},
		methods: {
			getData: function(){
				let that = this;
				that.greq('get', '/api/micro_orders', false, function(rs){
					if( rs.status ){
						that.orders = rs.data.orders;
						
						// 如果没有待结算的订单，不再刷新
						let tmp = rs.data.orders.filter(function(a){
							return a.status==1;
						});
						if(tmp.length > 0){
							if(!that._isDestroyed){
								that.timer_data = setTimeout(function(){
									that.getData();
								}, 1000)
							}
						}
					}
				})
			}
		},
		mounted(){
			this.getData();
		},
		beforeDestroy(){
			
		}
	}
</script>

<style>
</style>