<template>
	<div class="chistory_page">
		<div class="bg_white">
			<el-row class="p2 c_black2" type="flex" align="middle">
				<el-col :span=6 class="c_black2 tc" style="font-size:30px;">
					<span @click="$router.go(-1)">
						<i class="fa fa-angle-left" aria-hidden="true"></i>
					</span>
				</el-col>
				<el-col :span=12 class="f16">
					{{$t('交易历史订单')}}
				</el-col>
				<el-col :span=6 class="f12">
					<div>
						<!-- <el-popover placement="bottom" title="" width="100" trigger="manual" v-model="show_pop" class="p1">
							<div>
								<div class="pt3 pb3 tc" @click="type='all'">{{$t('全部')}}</div>
								<div class="pt3 pb3 tc" style="border-top:1px solid #eee;" @click="type='hold'">{{$t('自持')}}</div>
								<div class="pt3 pb3 tc" style="border-top:1px solid #eee;" @click="type='wait'">{{$t('挂单')}}</div>
							</div>
							<el-button type="text" size="small" class="c_black2" slot="reference" @click="show_pop=true">{{$t('更多筛选')}}</el-button>
						</el-popover> -->
						<el-select v-model="type" placeholder="请选择" size="mini">
							<el-option :label="$t('全部')" value="all"></el-option>
							<el-option :label="$t('自持')" value="hold"></el-option>
							<el-option :label="$t('挂单')" value="wait"></el-option>
						</el-select>
					</div>
				</el-col>
			</el-row>
		</div>

		<div class="">
			<div class="m3 p3 bg_white bradius8 bshadow f12" v-for="item in datalist" v-bind:key="item.id">
				<el-row type="flex" align="middle" style="border-bottom: 1px solid #eee;" class="pb2">
					<el-col :span="12" class="tl">
						<img :src="item.logo" width="30px" alt="">
					</el-col>
					<el-col :span="12" class="tr">
						<span class="c_up" v-if="item.hyzd==0"><i class="fa fa-line-chart"></i> {{$t('买多')}}</span>
						<span class="c_down" v-if="item.hyzd==1"><i class="fa fa-line-chart"></i> {{$t('买空')}}</span>
					</el-col>
				</el-row>

				<el-row class="pt2">
					<el-col :span="12" class="tl c_gray">{{$t('买入金额')}}</el-col>
					<el-col :span="12" class="tr">{{item.num}}</el-col>
				</el-row>
				<el-row class="pt2" v-if="item.s_price_type>=0">
					<el-col :span="12" class="tl c_gray">{{$t('买入价格')}}</el-col>
					<el-col :span="12" class="tr">{{item.buyprice}}</el-col>
				</el-row>
				<el-row class="pt2" v-if="item.s_price_type>=0">
					<el-col :span="12" class="tl c_gray">{{$t('卖出价格')}}</el-col>
					<el-col :span="12" class="tr">{{item.sellprice}}</el-col>
				</el-row>
				<el-row class="pt2" v-if="item.s_price_type>1">
					<el-col :span="12" class="tl c_gray">{{$t('挂单手数')}}</el-col>
					<el-col :span="12" class="tr">{{item.s_hand}}</el-col>
				</el-row>
				<el-row class="pt2" v-if="item.s_price_type>1">
					<el-col :span="12" class="tl c_gray">{{$t('止盈价格')}}</el-col>
					<el-col :span="12" class="tr">{{item.s_stop_win}}</el-col>
				</el-row>
				<el-row class="pt2" v-if="item.s_price_type>1">
					<el-col :span="12" class="tl c_gray">{{$t('止损价格')}}</el-col>
					<el-col :span="12" class="tr">{{item.s_stop_loss}}</el-col>
				</el-row>
				<el-row class="pt2">
					<el-col :span="12" class="tl c_gray">{{$t('手续费')}}</el-col>
					<el-col :span="12" class="tr">{{item.s_fee}}</el-col>
				</el-row>
				<el-row class="pt2" v-if="item.s_price_type>=0">
					<el-col :span="12" class="tl c_gray">{{$t('盈利')}}</el-col>
					<el-col :span="12" class="tr">
						<span class="c_up" v-if="item.is_win==1">{{item.ploss}}</span>
						<span class="c_down" v-if="item.is_win==2">-{{item.ploss}}</span>
					</el-col>
				</el-row>

				<el-row class="pt2 mt2" style="border-top:1px solid #eee;">
					<el-col :span="12" class="tl">
						<div>{{$t('买入时间')}}</div>
						<div class="mt2">{{item.buytime}}</div>
					</el-col>
					<el-col :span="12" class="tr" v-if="item.s_price_type>=0">
						<div>{{$t('卖出时间')}}</div>
						<div class="mt2">{{item.selltime}}</div>
					</el-col>
					<el-col :span="12" class="tr" v-if="item.s_price_type>1">
						<div>{{$t('卖出时间')}}</div>
						<div class="mt2">{{item.selltime}}</div>
					</el-col>
				</el-row>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "ContractHistory",
		data() {
			return {
				show_pop: false,
				type: 'all',
				orders: [],
				page: 1,
				page_count: 1,
			}
		},
		computed: {
			datalist: function() {
				let type = this.type;
				return this.orders.filter(function(a) {
					if (type == 'all') {
						return true
					} else if (type == 'hold') {
						return a.s_price_type == 0
					} else {
						return a.s_price_type == 1
					}
				})
			}
		},
		methods: {
			getData() {
				let that = this;
				that.greq('get', '/api/orders/page/' + this.page, false, function(rs) {
					if (rs.status) {
						// that.orders = rs.data.data
						for (let row of rs.data.data) {
							that.orders.push(row)
						}
						that.page_count = rs.data.pages

						if (that.page == that.page_count) {
							// hide load more button
							// ...
						}
					} else {
						that.gerror(rs.msg);
					}
				});
			}
		},
		mounted() {
			this.getData();
		}
	}
</script>

<style>
	.el-popover {
		min-width: 50px;
		padding: 0px;
	}
</style>
