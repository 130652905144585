<template>
	<div class="asset_page">
		<div class="bg_blue3 c_white" style="height:200px;">
			
			<div style="height:200px;position:absolute;top:0;right:0;z-index:0;">
				<img src="../assets/asset_bg.svg" alt="">
			</div>
			<div class="pl3 pt3 f12 tl w100">
				<div>{{$t('浮动盈亏')}}</div>
				<div class="mt1 f16 fbold">${{total_profit}}</div>
			</div>

			<div>
				<div>{{$t('账户余额')}}</div>
				<div class="mt2" style="font-size:22px;">{{info.total_money}}</div>
			</div>

			<div class="pt4">
				<el-row type="flex" align="middle">
					<el-col :span="1">
						<div class="swiper-button-prev" style="position: normal;color:#fff;font-size: 20px;"></div>
					</el-col>
					<el-col :span="22">
						<swiper ref="mySwiper" :options="swiperOptions">
							<swiper-slide>
								<div class="f14">${{info.money}}</div>
								<div class="c_blue_desc f12">{{$t('可用保证金')}}</div>
							</swiper-slide>
							<!-- <swiper-slide>
								<div class="f14">${{info.contract_use}}</div>
								<div class="c_blue_desc f12">{{$t('已用保证金')}}</div>
							</swiper-slide>
						
							<swiper-slide>
								<div class="f14">${{(info.money+info.contract_use+info.money_frozen).toFixed(4)}}</div>
								<div class="c_blue_desc f12">{{$t('账户净值')}}</div>
							</swiper-slide>
							<swiper-slide>
								<div class="f14">${{info.money_frozen}}</div>
								<div class="c_blue_desc f12">{{$t('冻结金额')}}</div>
							</swiper-slide> -->
						</swiper>
					</el-col>
					<el-col :span="1">
						<div class="swiper-button-next" style="position: normal;color:#fff;font-size: 20px;"></div>
					</el-col>
				</el-row>
			</div>
			
			<div class="bg_white br10 m4 p3 c_black2">
				<el-row>
					<el-col :span="12">
						<div @click="goto_recharge()">
							<div>
								<img src="../assets/recharge2.png" width="30" alt="">
							</div>
							<div>{{$t('充币')}}</div>
						</div>
					</el-col>
					<el-col :span="12">
						<div @click="$router.push('/withdraw')">
							<div>
								<img src="../assets/withdraw.png" width="30" alt="">
							</div>
							<div>{{$t('提币')}}</div>
						</div>
					</el-col>
					
				</el-row>
				<el-row class="pt4">
					<el-col :span="12">
						<div @click="$router.push('/exchange')">
							<div>
								<img src="../assets/exchange.png" width="30" alt="">
							</div>
							<div>{{$t('兑换')}}</div>
						</div>
					</el-col>
					<el-col :span="12">
						<div @click="$router.push('/earn')">
							<div>
								<img src="../assets/finance.png" width="30" alt="">
							</div>
							<div>{{$t('矿池')}}</div>
						</div>
					</el-col>
				</el-row>
			</div>
			
			<!-- 币币账户和合约账户 -->
			<div class="c_black bg_white">
				<div>
					<el-tabs v-model="tab" :stretch=true class="m0">
						<el-tab-pane :label="$t('币币账户')" name="0">
							<div class="p3" @click="switch_coin('usdt')">
								<el-row class="tl" type="flex" align="bottom">
									<el-col :span="4">
										<img src="../assets/usdt.png" width="40" alt="">
									</el-col>
									<el-col :span="7">
										<div class="fbold">USDT</div>
										<div class="c_gray f12">{{$t('可用')}}</div>
										<div>{{info.money}}</div>
									</el-col>
									<el-col :span="6">
										<div class="c_gray f12">{{$t('冻结')}}</div>
										<div>{{info.money_frozen}}</div>
									</el-col>
									<el-col :span="7">
										<div class="c_gray f12">{{$t('折合')}}</div>
										<div>{{(info.money+info.money_frozen).toFixed(4)}}</div>
									</el-col>
								</el-row>
							</div>
							<div class="p3" style="border-top:1px solid #f3f3f3;" @click="switch_coin('btc')">
								<el-row class="tl" type="flex" align="bottom">
									<el-col :span="4">
										<img src="../assets/btc.png" width="40" alt="">
									</el-col>
									<el-col :span="7">
										<div class="fbold">BTC</div>
										<div class="c_gray f12">{{$t('可用')}}</div>
										<div>{{info.btc}}</div>
									</el-col>
									<el-col :span="6">
										<div class="c_gray f12">{{$t('冻结')}}</div>
										<div>{{info.btc_frozen}}</div>
									</el-col>
									<el-col :span="7">
										<div class="c_gray f12">{{$t('折合')}}</div>
										<div>{{info.btc_to_usdt}}</div>
									</el-col>
								</el-row>
							</div>
							<div class="p3" style="border-top:1px solid #f3f3f3;" @click="switch_coin('eth')">
								<el-row class="tl" type="flex" align="bottom">
									<el-col :span="4">
										<img src="../assets/eth.png" width="40" alt="">
									</el-col>
									<el-col :span="7">
										<div class="fbold">ETH</div>
										<div class="c_gray f12">{{$t('可用')}}</div>
										<div>{{info.eth}}</div>
									</el-col>
									<el-col :span="6">
										<div class="c_gray f12">{{$t('冻结')}}</div>
										<div>{{info.eth_frozen}}</div>
									</el-col>
									<el-col :span="7">
										<div class="c_gray f12">{{$t('折合')}}</div>
										<div>{{info.eth_to_usdt}}</div>
									</el-col>
								</el-row>
							</div>
						</el-tab-pane>
						
						<el-tab-pane :label="$t('合约账户')" name="1">
							<!-- 持仓记录 -->
							<div class="m3">
								<div>
									<el-row type="flex" align="middle" class="f12">
										<el-col :span="6">
											<div @click="record.type=0">
												<div class="bg_blue2 br6 c_white w80 pt2 pb2" v-if="record.type==0">{{$t('市价委托')}}</div>
												<div class="empty_btn w80" v-if="record.type==1">{{$t('市价委托')}}</div>
											</div>
										</el-col>
										<el-col :span="6">
											<div @click="record.type=1">
												<div class="bg_blue2 br6 c_white w80 pt2 pb2" v-if="record.type==1">{{$t('限价委托')}}</div>
												<div class="empty_btn w80" v-if="record.type==0">{{$t('限价委托')}}</div>
											</div>
										</el-col>
										<el-col :span="11" class="tr">
											<router-link class="c_blue" to="/contract_history">{{$t('全部')}}</router-link>
										</el-col>
										<el-col :span="1">
											<span></span>
										</el-col>
									</el-row>
								</div>
							
								<!-- 执行中的订单 -->
								<div class="mt5 pb5" v-if="orders_pending.length==0 && record.type==0">
									<div><img src="../assets/nodata.png" height="50px" alt=""></div>
									<div class="mt2 c_gray f12">{{$t('暂无数据')}}</div>
								</div>
							
								<div class="mt3" v-if="orders_pending.length>0 && record.type==0">
									<div class="p3" v-for="item in orders_pending" v-bind:key="item.id"
										style="border-bottom: 1px solid #ddd;">
										<el-row>
											<el-col :span="2">
												<span class="bg_up p1 c_white br4 f12" v-if="item.type==0">{{$t('买')}}</span>
												<span class="bg_down p1 c_white br4 f12" v-if="item.type==1">{{$t('卖')}}</span>
											</el-col>
											<el-col :span="16" class="tl">
												{{item.pname}} x {{item.hand}} {{$t('手')}}
											</el-col>
											<el-col :span="6">
												<span class="c_up" v-if="item.profit>0">{{item.profit}}</span>
												<span class="c_down" v-if="item.profit<0">{{item.profit}}</span>
												<span v-if="item.profit==0">{{item.profit}}</span>
											</el-col>
										</el-row>
							
										<el-row type="flex" align="middle" class="f12">
											<el-col :span="9" class="tl mt2">
												<div>
													<span class="c_gray">{{$t('开仓价')}}</span>
													<span class="c_blue pl2">{{item.buyprice}}</span>
												</div>
												<div class="mt1">
													<span class="c_gray">{{$t('止盈价')}}</span>
													<span class="c_blue pl2">{{item.stop_win}}</span>
												</div>
											</el-col>
											<el-col :span="9" class="tl mt2">
												<div>
													<span class="c_gray">{{$t('当前价')}}</span>
													<span class="c_blue pl2">{{item.price}}</span>
												</div>
												<div class="mt1">
													<span class="c_gray">{{$t('止损价')}}</span>
													<span class="c_blue pl2">{{item.stop_loss}}</span>
												</div>
											</el-col>
											<el-col :span="6" class="mt2">
												<div class="bg_blue2 br6 c_white pt2 pb2 f12" @click="order_sell(item.oid)">{{$t('平仓')}}
												</div>
											</el-col>
										</el-row>
									</div>
								</div>
							
							
								<!-- 挂单 -->
								<div class="mt5 pb5" v-if="orders_wait.length==0 && record.type==1">
									<div><img src="../assets/nodata.png" height="50px" alt=""></div>
									<div class="mt2 c_gray f12">{{$t('暂无数据')}}</div>
								</div>
							
								<div class="mt3" v-if="orders_wait.length>0 && record.type==1">
									<div class="p3" v-for="item in orders_wait" v-bind:key="item.id"
										style="border-bottom: 1px solid #ddd;">
										<el-row>
											<el-col :span="2">
												<span class="bg_up p1 c_white br4 f12" v-if="item.type==0">{{$t('买')}}</span>
												<span class="bg_down p1 c_white br4 f12" v-if="item.type==1">{{$t('卖')}}</span>
											</el-col>
											<el-col :span="16" class="tl">
												{{item.pname}} x {{item.hand}} {{$t('手')}}
											</el-col>
											<el-col :span="6">
												<span></span>
											</el-col>
										</el-row>
							
										<el-row type="flex" align="middle" class="f12">
											<el-col :span="9" class="tl mt2">
												<div>
													<span class="c_gray">{{$t('挂单价')}}</span>
													<span class="c_blue pl2">{{item.buyprice}}</span>
												</div>
											</el-col>
											<el-col :span="9" class="tl mt2">
												<div>
													<span class="c_gray">{{$t('当前价')}}</span>
													<span class="c_blue pl2">{{item.price}}</span>
												</div>
											</el-col>
											<el-col :span="6" class="mt2">
												<div class="bg_blue2 br6 c_white pt2 pb2 f12" @click="order_cancel(item.oid)">
													{{$t('撤单')}}</div>
											</el-col>
										</el-row>
									</div>
								</div>
								<!-- end 挂单 -->
							</div>
						</el-tab-pane>
					</el-tabs>
				</div>
			</div>
		</div>
		
		
	</div>
</template>

<script>
	import {
		Swiper,
		SwiperSlide
	} from 'vue-awesome-swiper'
	import 'swiper/css/swiper.css'

	export default {
		name: "AssetView",
		components: {
			Swiper,
			SwiperSlide,
		},
		data() {
			return {
				show_coin_type: '',
				tab: "0",
				info: {
					total_money: 0.00,
					profit: 0.00,
					money: 0.000,
					contract_use: 0.00,
					money_frozen: 0.00,
					btc: 0.00,
					btc_frozen: 0.00,
					btc_to_usdt: 0.00,
					eth: 0.00,
					eth_frozen: 0.00,
					eth_to_usdt: 0.00,
				},
				contract: {
					type: 0 ,// 市价
					datas: [],
				},
				swiperOptions: {
					height: 300,
					// pagination: {
					// 	el: '.swiper-pagination',
					// 	clickable: true,
					// },
					slidesPerView: 3,
					slidesPerGroup: 3,
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
						enabled: true,
					}
				},
				timer_data: false,
				record: {
					type: 0, // 市价
					datas: [],
				}
			}
		},
		computed: {
			orders_pending: function() {
				return this.record.datas.filter(function(a) {
					return a.status == 1;
				});
			},
			orders_wait: function() {
				return this.record.datas.filter(function(a) {
					return a.status == 0;
				});
			},
			total_profit: function() {
				let profit = 0.00;
				for (let item of this.orders_pending) {
					profit = profit + parseFloat(item.profit);
				}
				return profit.toFixed(2);
			}
		},
		methods:{
			getData(){
				let that = this;
				this.greq('get', '/api/asset', false, function(rs){
					if( rs.status ){
						// that.info = rs.data.info;
						for(let k in that.info ){
							if( k == 'total_money' ){
								if( that.show_coin_type == '' ){
									that.info[k] = rs.data.info[k];
								} else if( that.show_coin_type == 'btc') {
									that.info[k] = rs.data.info.btc + ' BTC';
								} else if( that.show_coin_type == 'eth'){
									that.info[k] = rs.data.info.eth + ' ETH';
								} else {
									that.info[k] = rs.data.info.money + ' USDT';
								}
							} else {
								that.info[k] = rs.data.info[k];
							}
						}
						
						// orders
						that.record.datas = rs.data.orders;
						
						if(!that._isDestroyed){
							that.timer_data = setTimeout(function(){
								that.getData();
							}, 2000);
						}
					}
				});
			},
			order_sell(oid) {
				let that = this;
				this.greq('post', '/api/contract_sell', {
					oid: oid
				}, function(rs) {
					if (rs.status) {
						that.order.datas = [];
						that.$message(rs.msg);
					} else {
						that.gerror(rs.msg);
					}
				});
			},
			order_cancel(oid) {
				let that = this;
				this.greq('post', '/api/contract_cancel', {
					oid: oid
				}, function(rs) {
					if (rs.status) {
						that.$message(rs.msg);
					} else {
						that.gerror(rs.msg);
					}
				});
			},
			switch_coin(type){
				this.show_coin_type = type;
				console.log(type)
				if( type == 'usdt' ){
					this.info.total_money = this.info.money + ' USDT';
				} else if(type == 'btc') {
					this.info.total_money = this.info.btc + ' BTC';
				} else {
					this.info.total_money = this.info.btc + ' ETH';
				}
				
			},

			goto_recharge(){
				if( this.show_coin_type == 'btc' ){
					this.$router.push('/recharge/2')
				} else if( this.show_coin_type == 'usdt'){
					this.$router.push('/recharge/0')
				} else if( this.show_coin_type == 'eth' ){
					this.$router.push('/recharge/3')
				} else {
					this.$router.push('/recharge_list')
				}
			}
		},
		mounted() {
			this.getData();
		},
		beforeCreate(){
			if( !this.glogin() ){
				this.$router.push('/login');
			}
			return false;
		},
		beforeDestroy() {
			if( this.timer_data ){
				clearTimeout(this.timer_data);
			}
		}
	}
</script>

<style>
	.asset_page .swiper-button-prev:after, .asset_page .swiper-button-next:after{
		font-size: 16px;
	}
</style>
