<template>
	<div class="market_page">
		<div class="mt4">
			<el-row type="flex" align="middle">
				<el-col :span="7">
					<span></span>
				</el-col>
				<el-col :span="10">
					<el-radio-group v-model="tabType" size="small" style="display: none;">
						<el-radio-button label="0">{{$t('期货')}}</el-radio-button>
						<el-radio-button label="1">{{$t('合约')}}</el-radio-button>
					</el-radio-group>
				</el-col>
				<el-col :span="7">
					<router-link to="/focus_add" class="c_black2" v-if="tabCat=='4'">
						<i class="fa fa-plus"></i>
					</router-link>
				</el-col>
			</el-row>
			
		</div>

		<div class="m3">
			<el-tabs v-model="tabCat" :stretch=true>
				<el-tab-pane :label="row.name" :lazy=true :name="row.value" v-for="row in tabs" v-bind:key="row.id" :disabled="!row.show">
					<el-row class="m3 c_gray">
						<el-col :span="12" class="tl" style="text-indent: 20px;">{{$t('交易品种')}}</el-col>
						<el-col :span="6">{{$t('最新价')}}</el-col>
						<el-col :span="6">{{$t('涨跌幅')}}</el-col>
					</el-row>
					<div class="pt3 pb3 p2" style="border-bottom: 1px solid #eee;" v-for="item in currentData" v-bind:key="item.id" @click="gototrade(item.id)">
						<el-row type="flex" align="middle">
							<el-col :span=3 class="tc">
								<div style="border-radius: 15px;width:30px;height:30px;display: flex;align-items: center;">
									<img :src="item.logo" width="30px" style="border-radius: 15px;" alt="">
								</div>
								
							</el-col>
							<el-col :span=9 class="tl">
								<div class="f14 fbold">{{item.title}}</div>
								<div class="c_gray f12 mt2">volumn {{item.vol}}</div>
							</el-col>
							
							<el-col :span=6 class="fbold">
								{{item.price}}
							</el-col>
							
							<el-col :span="6">
								<span class="p2 c_white br10 bg_up f13" v-if="item.change>=0">
									{{item.change}}%
								</span>
								<span class="p2 c_white br10 bg_down f13" v-if="item.change<0">
									{{item.change}}%
								</span>
							</el-col>
						</el-row>
					</div>
				</el-tab-pane>
				
				
			</el-tabs>
		</div>
	</div>
</template>

<script>
	export default {
		name: "MarketView",
		data() {
			return {
				tabType: 1,
				tabCat: "1",
				tabs: [
					{name:this.$t('关注'),value:"4",show:this.glogin()},
					{name:this.$t('国际期货'),value:"0",show:true},
					{name:this.$t('加密货币'),value:"1",show:true},
					{name:this.$t('外汇'),value:"2",show:true},
					{name:this.$t('贵金属'),value:"3",show:true},
				],
				products: [],
				currentData: [],
				login: this.glogin(),
				timer: false,
			}
		},
		watch:{
			tabCat: function(val){
				this.currentData = this.filterData(val);
			},
		},
		computed: {
		},
		methods: {
			initData(){
				let that = this;
				this.greq('get', '/main/products', false, function(rs){
					that.products = rs.data;
					that.currentData = that.filterData(that.tabCat);
					
					if(!that._isDestroyed){
						that.timer = setTimeout(function(){
							that.initData();
						}, 1000);
					}
				});
			},
			gototrade(pid){
				if(this.tabType==0){
					this.$router.push('/coin/'+pid)
				}else{
					this.$router.push('/contract/'+pid)
				}
				
			},
			filterData(cat){
				let that = this;
				return this.products.filter(function(item){
					item.title = that.$t(item.title)
					console.log('test...')
					return item.category == cat
				})
			}
		},
		mounted(){
			this.initData();
		},
		destroyed(){
			if(this.timer){
				clearTimeout(this.timer);
			}
		}
	}
</script>

<style>
	/* .market_page .el-radio-button__orig-radio:checked+.el-radio-button__inner {
		background-color: #5670f6;
		border-color: #5670f6;
		box-shadow: none;
	} */
/* 	.market_page .el-tabs__item.is-active{
		color: #5670f6;
	} */
</style>
