<template>
	<div class="content_page">
		<div class="bg_white">
			<el-row class="p2 c_black" type="flex" align="middle">
				<el-col :span=4 class="c_blue tc" style="font-size:30px;">
					<div class="c_black" @click="$router.go(-1)">
						<i class="fa fa-angle-left" aria-hidden="true"></i>
					</div>
				</el-col>
				<el-col :span=16 class="f16">
					{{title}}
				</el-col>
				<el-col :span=4 class="">
					<span></span>
				</el-col>
			</el-row>
		</div>
		
		<div class="m4">
			<el-card class="box-card tl">
				<div v-html="content"></div>
			</el-card>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'ContentType',
		data(){
			return {
				content: "",
				title: "",
				backurl: "/",
				titles: {
					"2": this.$t('用户协议'),
					"7": this.$t('提现协议'),
				}
			}
		},
		mounted: function(){
			// get content
			let that = this;
			this.$parent.loading = true;
			this.greq('post', '/main/content', {type:this.type}, function(rs){
				that.$parent.loading = false;
				if( !rs.status ){
					that.$message({message:rs.msg, type:'error'});
				} else {
					that.content = rs.data.content;
					// that.title = rs.data.title;
				}
			}, function(){
				that.$parent.loading = false;
			});
			
			this.title = this.titles[this.type]
		},
		props: ['type']
	}
</script>

<style>
</style>