<template>
	<div class="miner_page" v-loading.fullscreen.lock="loading" element-loading-background="rgba(0,0,0,0.4)">
		<div class="bg_white">
			<el-row class="p2 c_black2" type="flex" align="middle">
				<el-col :span=4 class="c_blue tc" style="font-size:30px;">
					<router-link to="/" class="c_black">
						<i class="fa fa-angle-left" aria-hidden="true"></i>
					</router-link>
				</el-col>
				<el-col :span=16 class="f16">
					{{$t('质押挖矿')}}
				</el-col>
				<el-col :span=4 class="">
					<span></span>
				</el-col>
			</el-row>
		</div>
		
		<div class="p4 c_white" style="background-color: #0246FF;">
			<div class="mt5 tl">
				{{$t('质押金额')}} :
			</div>
			<div class="mt3 tl f30 fbold">${{total}}</div>
			
			<div style="position:relative;height:1px;top:-80px;text-align: right;">
				<div>
					<!-- <el-button type="success" style="width:120px;" round size="small">{{$t('规则')}}</el-button> -->
				</div>
				<div class="mt3">
					<router-link to="/miner_orders">
						<el-button type="primary" plain style="width:120px;" round size="small">{{$t('托管订单')}}</el-button>
					</router-link>
				</div>
			</div>
		</div>
		
		<div class="bg_blue c_white pt4 pb4" style="background-color: #0246FF;">
			<el-row>
				<el-col :span="12" style="border-right: 1px solid #629af1;">
					<div class="f18 fbold">{{yestoday_profit}}</div>
					<div style="color:#93c9ff">{{$t('昨日收益')}}</div>
				</el-col>
				<el-col :span="12">
					<div class="f18 fbold">{{total_profit}}</div>
					<div style="color:#93c9ff">{{$t('总收益')}}</div>
				</el-col>
			</el-row>
		</div>
		
		<div class="m3 p3 bg_white bradius8" v-for="item of minerList" v-bind:key="item.id">
			<el-row type="flex" align="middle">
				<el-col :span="4">
					<img src="../assets/avatar.png" width="36px" alt="">
				</el-col>
				<el-col :span="12" class="tl">
					<span class="f14 fbold">{{item.title[lang]}}</span>
				</el-col>
				<el-col :span="8" class="tr">
					<el-button type="primary" size="mini" @click="buy(item.id)">{{$t('认购')}}</el-button>
				</el-col>
			</el-row>
			
			<el-row class="mt3">
				<el-col :span="item.limit==0?8:6">
					<div class="c_gray f12">{{$t('单笔限制')}}</div>
					<div class="mt2 c_blue">{{item.min}}~{{item.max}}</div>
				</el-col>
				<el-col :span="item.limit==0?8:6">
					<div class="c_gray f12">{{$t('收益率')}}/{{$t('天')}}</div>
					<div class="mt2 c_blue">{{item.profit}}%</div>
				</el-col>
				<el-col :span="item.limit==0?8:6" v-if="item.limit>0">
					<div class="c_gray f12">{{$t('认购次数')}}</div>
					<div class="mt2 c_blue">{{item.times}}/{{item.limit}}</div>
				</el-col>
				<el-col :span="item.limit==0?8:6">
					<div class="c_gray f12">{{$t('周期')}}</div>
					<div class="mt2 c_blue">{{item.days}}{{$t('天')}}</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'MinerView',
		data(){
			return {
				loading: false,
				minerList: [],
				lang: this.glang(),
				total: 0,
				total_profit: 0,
				yestoday_profit: 0,
			}
		},
		methods: {
			buy(id){
				this.$router.push('/miner_buy/'+id)
			}
		},
		mounted: function(){
			// get miner list
			let that = this;
			this.greq('get', '/api/miner_list', false, function(rs){
				if( rs.status ){
					that.minerList = rs.data.list;
					that.total = rs.data.total;
					that.total_profit = rs.data.total_profit;
					that.yestoday_profit = rs.data.yestoday_profit;
				} 
			})
		}
	}
</script>

<style>
	.miner_page .el-button--success{
		background-color: #04ad8f;
		border-color: #04ad8f;
	}
</style>