<template>
	<div class="login_page">
		<div>
			<el-row class="m3">
				<el-col :span=10 style="font-size: 40px;" class="c_blue tl">
					<router-link to="/" class="c_blue">
						<i class="fa fa-angle-left" aria-hidden="true"></i>
					</router-link>
				</el-col>
				<el-col :span=14>
					<!-- 多语言选择 -->
					<stf-select v-model="value" style="width:160px; margin: 0 auto;float:right">
						<div slot="label">Input address</div>
						<div slot="value">
							<div v-if="value">
								<el-row type="flex" align="middle" justify="center">
									<el-col :span=8>
										<img :src="value.img" width="24px" class="mt1" alt="">
									</el-col>
									<el-col :span=12 class="f14 tl">{{value.text}}</el-col>
									<el-col :span=4>
										<i class="fa fa-angle-down f14"></i>
									</el-col>
								</el-row>
							</div>
						</div>
						<section class="options delivery_order__options">
							<stf-select-option v-for="item of countries" :key="item.value" :value="item" :class="{'stf-select-option_selected': item.value === (value && value.value)}">
								<el-row type="flex" align="middle" justify="center">
									<el-col :span=8 class="tc">
										<img class="mt2" :src="item.img" alt="" width="20px">
									</el-col>
									<el-col :span=16>{{item.text}}</el-col>
								</el-row>
							</stf-select-option>
						</section>
					</stf-select>
					<!-- end 多语言选择 -->
					
				</el-col>
			</el-row>
		</div>

		<div style="width:75px;margin:0 auto;margin-top:70px;">
			<img src="../assets/avatar.png" style="width: 100%;" alt="">
		</div>


		<el-tabs v-model="activeName" :stretch=true
			style="width:66%;margin:0 auto;padding-top:40px;" class="login_form">
			<el-tab-pane :label="$t('手机')" name="first">
				<div class="mt3">
					<div
						style="width: 1px;height: 1px;overflow: visible;z-index: 999;position: relative;top: 12px;left: 13px;">
						<span @click="drawer = true">+{{ areacode }}</span>
					</div>
					<el-input v-model="phone" :placeholder="$t('请输入手机号')" prefix-icon="el-icon-search123"></el-input>

					<el-input class="mt3" v-model="password" :placeholder="$t('请输入密码')" autocomplete="off" prefix-icon="fa fa-key"
						:show-password=true></el-input>

					<el-button type="primary" class="mt4 w100" round @click="submit">{{$t('登入')}}</el-button>
				</div>

			</el-tab-pane>


			<el-tab-pane :label="$t('邮箱')" name="second" class="mt3">
				<el-input v-model="email" :placeholder="$t('请输入邮箱')" prefix-icon="fa fa-inbox"></el-input>

				<el-input class="mt3" v-model="password2" :placeholder="$t('请输入密码')" autocomplete="off" prefix-icon="fa fa-key"
					:show-password=true></el-input>

				<el-button type="primary" class="mt4 w100" round @click="submit">{{$t('登入')}}</el-button>
			</el-tab-pane>
		</el-tabs>
		
		<div style="width: 66%;margin:0 auto;" class="pt4">
			<el-row>
				<el-col :span=12 class="tl">
					<router-link to="/register">{{$t('注册')}}</router-link>
				</el-col>
				
				<el-col :span=12 class="tr">
					<router-link to="/forgot">{{$t('忘记密码')}}</router-link>
				</el-col>
			</el-row>
		</div>


		<!-- <div ref="area_list" style="position: absolute;top:0;width:100%;height:100%;background-color:#fff;z-index: 999;"> -->
		<el-drawer :title="title" :visible.sync="drawer" :direction="direction" size="100%">
			<div class="mt2 p3">
				<el-input v-model="search" :placeholder="$t('搜索')"></el-input>
			</div>
			<div class="">
				<div class="tl m4" v-for="item of searchAreacode" v-bind:key="item.id" @click="areacode=item.code;drawer=false">
					{{item.title}} (+{{item.code}})
				</div>
			</div>
		</el-drawer>

	</div>
</template>

<script>
	import {
		StfSelect,
		StfSelectOption
	} from "stf-vue-select";
	import "stf-vue-select/dist/lib/stf-vue-select.min.css";

	export default {
		name: "LoginView",
		data() {
			return {
				search: "",
				activeName: "first",
				phone: "",
				password: "",
				email: "",
				password2: "",
				areacode: "66",
				drawer: false,
				direction: "ttb",
				title: this.$t("选择区号"),
				lang: "",
				value: null,
				countries: [{
						text: "English",
						img: require('../assets/flags/en.png'),
						value: 'en',
					},
					{
						text: "繁体中文",
						img: require('../assets/flags/tw.png'),
						value: 'tw',
					},
					{
						text: 'ไทย',
						img: require('../assets/flags/th.png'),
						value: 'th',
					},
					{
						text: '日本語',
						img: require('../assets/flags/jp.png'),
						value: 'jp',
					},
					{
						text: 'Español',
						img: require('../assets/flags/es.png'),
						value: 'es',
					},
					{
						text: 'Français',
						img: require('../assets/flags/fr.png'),
						value: 'fr',
					},
					{
						text: 'Deutsch',
						img: require('../assets/flags/de.png'),
						value: 'de',
					},
					{
						text: '한국인',
						img: require('../assets/flags/ko.svg'),
						value: 'ko',
					},
					{
						text: 'Tiếng Việt',
						img: require('../assets/flags/vi.svg'),
						value: 'vi',
					},
				],
				areacodes: [],
			}
		},
		watch: {
			drawer: function(n, o){
				if( n ){
					this.title = this.$t("选择区号");
				}
				console.log(o)
			},
			value: function(newValue){
				console.log('new:')
				console.log(newValue)
				
				// set local plus.storage.
				localStorage.setItem('lang', newValue.value)
				
				// set i18n
				this.$parent.$i18n.locale = newValue.value
				// // set cookie
				// this.$cookie.set('lang', newValue.value, 365)
				
				// set areacodes match lang
				let langs = {
					zh: 1,
					en: 2,
					tw: 3,
					th: 4,
					jp: 5,
					es: 6,
					fr: 7,
					de: 8,
					ko: 9,
					vi: 10
				}
				let langIndex = langs[newValue.value]
				let areacodes = require('../json/areacodes.json')
				let newAreacodes = [];
				for(let row of areacodes){
					newAreacodes.push({
						code: row[0],
						title: row[langIndex]
					})
				}
				this.areacodes = newAreacodes;
			}
		},
		computed: {
			searchAreacode: function(){
				let s = this.search.toLowerCase().trim()
				console.log(s)
				return this.areacodes.filter(function(item){
					if(item.title.toLowerCase().indexOf(s) > -1 || item.code.indexOf(s)>-1){
						return true;
					}
					return false;
				});

			}
		},
		methods: {
			showAreaList: function() {
				alert('sdf');
			},
			
			// login submit
			submit: function(){
				let name = ""
				let pwd = ""
				if( this.activeName == 'first' ){
					if( !this.phone ){
						this.$message({message:this.$t("请输入手机号"),type:'error'})
						return
					}
					
					if( !this.password ){
						this.$message({message:this.$t("请输入密码"),type:'error'})
					}
					name = "+" + this.areacode + this.phone
					pwd = this.password
				} else {
					if( !this.email ){
						this.$message({message:this.$t("请输入邮箱"),type:'error'})
						return
					}
					if( !this.password2 ){
						this.$message({message:this.$t("请输入密码"),type:'error'})
					}
					name = this.email
					pwd = this.password2
				}
				
				// submit
				let that = this;
				this.greq('post', '/main/login', {name:name,pwd:pwd}, function(data){
					if( data.status ){
						that.$message(data.msg)
						
						// save token
						localStorage.setItem('token', data.data.token)
						
						// goto home
						setTimeout(function(){
							that.$router.push('/');
						}, 1000);
						
					} else {
						that.$message({message:data.msg,type:'error'})
					}
				})
			}
		},
		mounted() {
			// set lang
			let glang = this.glang()
			console.log(glang)
			
			for(let row of this.countries){
				if( row.value == glang ){
					this.value = row
				}
			}
			
			this.$parent.show_footer = false;
			
			// console.log(this.$parent);
			// let p = this.$parent;
			// setTimeout(function(){
			// 	p.$i18n.locale = 'en'
			// }, 3000)
			// console.log(this._isDestroyed)
		},
		beforeDestroy() {
			this.$parent.show_footer = true;
		},
		components: {
			StfSelectOption,
			StfSelect
		},
		beforeCreate() {
			if(this.glogin()){
				this.$router.push('/');
			}
		}
	}
</script>

<style>
	.login_form .el-input--prefix .el-input__inner {
		padding-left: 60px;
	}

	.login_form .el-input__icon {
		width: 60px;
	}

	.login_page .el-drawer__header {
		padding: 10px 10px;
		border-bottom: 1px solid #eee;
	}
	
	.stf-select-options{
		max-height:2000px;
	}
	
	.stf-select-option{
		padding:0px 0px  4px 4px !important;
		border-bottom:0px;
		font-size:12px;
	}
	.stf-select__value{
		line-height: inherit;
	}
	
	
	.stf-select_sm .stf-select__value {
		line-height: inherit;
	}
</style>
