<template>
	<div class="trade_page" v-loading.fullscreen.lock="loading" element-loading-background="rgba(0,0,0,0.4)">
		<div class="tp_head pb2 bg_white"
			style="top:0px;width: 100%;border-bottom: 1px solid #f3f3f3;z-index: 999;height:170px;">
			<div>
				<el-row class="p2 c_black2" type="flex" align="middle">
					<el-col :span=4 class="c_black2 tc" style="font-size:30px;">
						<span @click="goback">
							<i class="fa fa-angle-left" aria-hidden="true"></i>
						</span>
					</el-col>
					<el-col :span=16 class="f16">
						{{info.title}}
					</el-col>
					<el-col :span=4 class="">
						<span></span>
					</el-col>
				</el-row>
			</div>

			<div class="m3">
				<el-row>
					<el-col :span="12" class="tl fbold" style="text-indent: 10px;">
						<div @click="showSiderBar">
							{{info.title}} <i class="fa fa-angle-down"></i>
						</div>
					</el-col>
					<el-col :span="12" class="tr">
						<div @click="$router.push('/trade_history')">
							<i class="fa fa-file"></i> {{$t('持仓')}}
						</div>
					</el-col>
				</el-row>
			</div>

			<div class="pl3 pr3 pb2">
				<el-row type="flex" align="middle">
					<el-col :span="10" class="tl">
						<div class="c_gray f12">{{$t('最新价')}}</div>
						<div :class="{'c_down':info.change<0,' c_up':info.change>=0}">
							<div class="f18 fbold mt2">{{info.c}}</div>
							<div class="f12">
								{{info.ce}} {{info.change}}%
							</div>
						</div>
					</el-col>

					<el-col :span="7">
						<div class="c_gray f12">{{$t('开盘')}}</div>
						<div class="">{{info.o}}</div>

						<div class="c_gray f12 mt2">{{$t('最低')}}</div>
						<div class="">{{info.l}}</div>
					</el-col>
					<el-col :span="7">
						<div class="c_gray f12">{{$t('成交量')}}</div>
						<div class="">${{info.vol}}</div>

						<div class="c_gray f12 mt2">{{$t('最高')}}</div>
						<div class="">{{info.h}}</div>
					</el-col>
				</el-row>
			</div>
		</div>
		<!-- end head -->

		<!-- chart -->
		<div class="chart bg_white" style="padding-top:10px">
			<!-- <div>
				<el-row>
					<el-col :span="4" v-for="item in intervals" v-bind:key="item.id">
						{{item}}
					</el-col>
				</el-row>
			</div> -->
			<div class="p2">
				<el-row>
					<el-col :span="4" v-for="item in intervals" v-bind:key="item.id" class="tc">
						<div @click="current_interval=item" v-if="current_interval!=item" class="pt2 pb2">{{item}}</div>
						<div @click="current_interval=item" v-if="current_interval==item" class="bg_blue c_white pt2 pb2">{{item}}</div>
					</el-col>
				</el-row>
			</div>

			<div id="chart" style="width: 100%;height:420px;" class="mt1"></div>

		</div>
		<!-- end chart -->

		<!-- transactions -->
		<div class="p2" v-if="model=='none'">
			<el-row class="c_gray f12">
				<el-col :span="8">{{$t('成交价')}}</el-col>
				<el-col :span="8">{{$t('方向')}}</el-col>
				<el-col :span="8">{{$t('时间')}}</el-col>
			</el-row>
		</div>
		<div class="p2 bg_white" v-if="model=='none'">
			<el-row class="pb2" v-for="item in txs" v-bind:key="item.id">
				<el-col :span="8">{{item.price}}</el-col>
				<el-col :span="8">
					<span class="c_up" v-if="item.type==0">{{$t('买多')}}</span>
					<span class="c_down" v-if="item.type==1">{{$t('买空')}}</span>
				</el-col>
				<el-col :span="8">
					{{item.t}}
				</el-col>
			</el-row>
		</div>
		<!-- end trans -->


		<div style="position: fixed;bottom:54px;border-top:1px solid #eee;z-index:99;" class="bg_white w100 pt2 pb2" v-if="model=='none'">
			<el-row>
				<el-col :span="12">
					<div class="bg_up c_white br10 w80 pt2 pb2" style="margin:0 auto;" @click="micro_type=0;showOrderUI=true;">{{$t('买多')}}</div>
				</el-col>

				<el-col :span="12">			
					<div class="bg_down c_white br10 w80 pt2 pb2" style="margin:0 auto;" @click="micro_type=1;showOrderUI=true;">{{$t('买空')}}</div>
				</el-col>
			</el-row>
		</div>
		
		<div style="position: fixed;border-top:1px solid #eee;z-index:99;bottom:0px;" class="bg_white w100 pt2 pb2" v-if="from=='contract'">
			<el-row>
				<el-col :span="12">
					<div class="bg_up c_white br10 w80 pt2 pb2" style="margin:0 auto;" @click="$router.push('/contract/'+id)">{{$t('买多')}}</div>
				</el-col>
		
				<el-col :span="12">			
					<div class="bg_down c_white br10 w80 pt2 pb2" style="margin:0 auto;" @click="$router.push('/contract/'+id+'/1')">{{$t('买空')}}</div>
				</el-col>
			</el-row>
		</div>


		<el-dialog :title="$t('订单确认')" :visible.sync="showOrderUI" :close-on-click-modal=false width="80%">
			<div class="bg_gray p3 bradius8 f12">
				<el-row>
					<el-col :span="12" class="tl">{{$t('名称')}}</el-col>
					<el-col :span="12" class="tr">{{info.title}}</el-col>
				</el-row>
				<el-row class="mt2">
					<el-col :span="12" class="tl">{{$t('方向')}}</el-col>
					<el-col :span="12" class="tr">
						<span v-if="micro_type==0">{{$t('买多')}}</span>
						<span v-if="micro_type==1">{{$t('买空')}}</span>
					</el-col>
				</el-row>
				<el-row class="mt2">
					<el-col :span="12" class="tl">{{$t('最新价')}}</el-col>
					<el-col :span="12" class="tr">{{info.c}}</el-col>
				</el-row>
			</div>

			<div class="mt2">
				<!-- <div class="tl">{{$t('选择到期时间(左滑收益更高)')}}</div> -->
				<div style="overflow: scroll;" class="mt2">
					<table border="0">
						<tr>
							<td v-for="item in info.micro_contract" v-bind:key="item.id">
								<div @click="micro_time=item.time;micro_rate=item.rate;">
									<div class="pt3 pl3 pr3 bradius8 bg_gray" style="width:45px;"
										v-if="item.time!=micro_time">
										<div class="fbold f16">{{item.time}}{{$t('s')}}</div>
										<div class="mt1 pt2 pb1 c_gray f12" style="border-top:1px solid #ddd;">
											{{item.rate}}%
										</div>
									</div>

									<div class="pt3 pl3 pr3 bradius8 bg_blue" style="width:45px;"
										v-if="item.time==micro_time">
										<div class="fbold f16 c_white">{{item.time}}{{$t('s')}}</div>
										<div class="mt1 pt2 pb1 c_white f12" style="border-top:1px solid #ddd;">
											{{item.rate}}%
										</div>
									</div>
								</div>
							</td>
						</tr>
					</table>
				</div>

				<div class="tl mt2">
					{{$t('投资金额')}}	
					<span class="c_gray f12">( {{$t('最低')}}: ${{micro_min}} )</span>
				</div>
				<div class="mt2">
					<el-input v-model="micro_amount" size="small" :placeholder="$t('投资金额')" @input="formatNumber"></el-input>
				</div>
				<div class="mt1 f12 c_gray">
					<el-row>
						<el-col :span="12" class="tl">{{$t('可用余额')}}: {{info.userinfo.money}}</el-col>
						<el-col :span="12" class="tr">{{$t('手续费')}}: 1 USDT</el-col>
					</el-row>
				</div>

				<div class="mt5">
					<el-button type="primary" round size="small" class="w80" @click="order_submit">{{order_btn_text}}</el-button>
				</div>
			</div>
		</el-dialog>


		<el-dialog :title="info.title" :visible.sync="showOrderTimerUI" :close-on-click-modal=false width="90%" top="30vh">
			<div v-if="showOrderTimer">
				<div style="height:1px;position:relative;">
					<circle-progress v-if="showOrderTimer" class="progress" key="animation-model" :isAnimation=false
						:totalTime="micro_time" :autoRun=true :isRound="true" width="120" radius="10" :progress="order.progress" barColor="#007aff" duration="0" delay="20" backgroundColor="#ddd" :countDownCallBack="order.callback" :secondCallback="order.secondCallback">
					</circle-progress>
				</div>
				<div style="height:120px;text-align: center;">
					<div class="mt4 f30 c_blue2">{{order.current_t}}</div>
					<div class="c_gray f12 mt1">{{$t('最新价')}}</div>
					<div class="c_black2 f12">{{info.c}}</div>
				</div>
			</div>
			
			<div v-if="showOrderLoading" style="height:100px;">
				<i class="fa fa-refresh fa-spin c_gray" style="font-size:60px;line-height:100px;"></i>
			</div>
			
			<div v-if="showOrderResult" style="height:100px;line-height: 100px;font-size:40px;">
				<span class="c_up" v-if="order.result>0">+{{order.result}}</span>
				<span class="c_down" v-if="order.result<=0">{{order.result}}</span>
			</div>
			
			<div class="f12 pt3" style="border-top:1px solid #eee;">
				<el-row type="flex" align="middle">
					<el-col :span="8">
						<div class="c_gray pb2">{{$t('名称')}}</div>
						<div>{{info.title}}</div>
					</el-col>
					<el-col :span="5">
						<div class="c_gray pb2">{{$t('方向')}}</div>
						<div class="c_up" v-if="micro_type==0">{{$t('买多')}}</div>
						<div class="c_down" v-if="micro_type==1">{{$t('买空')}}</div>
					</el-col>
					<el-col :span="7">
						<div class="c_gray pb2">{{$t('执行价')}}</div>
						<div>{{order.buyprice}}</div>
					</el-col>
					<el-col :span="4">
						<div class="c_gray pb2">{{$t('金额')}}</div>
						<div>{{micro_amount}}</div>
					</el-col>
					<!-- <el-col :span="5">
						<div class="c_gray pb2">{{$t('盈亏')}}</div>
						<div class="c_up" v-if="micro_ploss>=0">{{micro_ploss}}</div>
						<div class="c_down" v-if="micro_ploss<0">{{micro_ploss}}</div>
					</el-col> -->
				</el-row>
			</div>
			
			<div class="mt4">
				<el-button class="w100" type="primary" @click="showOrderTimerUI=false">{{$t('继续下单')}}</el-button>
			</div>
		</el-dialog>
		
		
		<market-sider-bar ref="sidebar" :itemCallback="sidebarCallback"></market-sider-bar>
	</div>
</template>

<script>
	import MarketSiderBar from '../components/MarketSideBar.vue'
	import klinecharts from 'klinecharts'
	import CircleProgress from '../components/circle-progress'

	export default {
		name: 'TradeView',
		components: {
			CircleProgress,
			MarketSiderBar
		},
		props:{
			id: {
				type: Number,
			},
			from: {
				type: String,
				default: "none",
			}
		},
		data() {
			return {
				showOrderUI: false,
				showOrderTimer: false,
				showOrderTimerUI: false,
				showOrderResult: false,
				showOrderLoading: false,
				orderType: this.$t('买多'),
				loading: false,
				model: this.from,
				title: "Coin market",
				intervals: ['1m', '5m', '15m', '30m', '1h', '1d'],
				current_interval: '15m',
				chart: null,
				info: {
					'c': 0,
					'o': 0,
					'h': 0,
					'l': 0,
					'vol': 0,
					'change': '0.00',
					'title': '...',
					'desc': '...',
					micro_contract: [],
					micro_fee: 0.00,
					userinfo: {
						money: 0.00,
					},
				},
				micro_time: 0,
				micro_rate: 0.00,
				// micro_fee: 0,
				micro_amount: '',
				micro_type: 0, // 0买多，1卖空
				timeout_kline: false,
				klinedata: [],
				time_req: 3000,
				txs: [],
				pid: this.id,
				order: {
					progress: 10,
					// total_t: 10, // use micro_time
					current_t: 0,
					callback: this.timerDonwCallback,
					secondCallback: this.timerSecondCallback,
					result: 0,
					oid: 0,
					resultTimer: false,
					buyprice: 0.00,
				},
				order_btn_text: this.$t('确认下单'),
			}
		},
		watch: {
			current_interval: function(val) {
				if (this.timeout_kline) {
					clearTimeout(this.timeout_kline);
					this.timeout_kline = false;
				}
				this.kline(val);
			},
			
			showOrderTimerUI: function(val){
				this.showOrderTimer = val
				
				
				if( val == false ){
					if( this.order.resultTimer ){
						clearTimeout(this.order.resultTimer)
						this.order.resultTimer = false;
					}
					this.showOrderLoading = false;
					this.showOrderResult = false;
				}
			}
		},
		computed: {
			micro_min(){
				let mmin = 0;
				for(let item of this.info.micro_contract){
					if( item.time == this.micro_time ){
						mmin = item.min
						break
					}
				}
				return mmin
			},
			micro_fee(){
				return (this.micro_amount*this.info.micro_fee/100).toFixed(2);
			},
			micro_ploss(){
				let ploss = this.micro_amount*this.micro_rate/100;
				// long
				if(this.micro_type==0){
					if(this.info.c > this.order.buyprice){
						return ploss;
					} else if(this.info.c < this.order.buyprice ){
						return -1*ploss;
					} else {
						return 0.00;
					}
				} else {
					//short
					if(this.info.c > this.order.buyprice){
						return -1*ploss;
					} else if(this.info.c < this.order.buyprice ){
						return ploss;
					} else {
						return 0.00;
					}
				}
			}
		},
		methods: {
			showSiderBar(){
				this.$refs.sidebar.drawer = true;
			},
			timerDonwCallback(){
				this.showOrderTimer = false;
				this.showOrderLoading = true;
				
				let that = this;
				this.greq('post', '/api/micro_result', {oid:this.order.oid}, function(rs){
					if( rs.status == 2 ){
						that.gerror(rs.msg);
					} else if( rs.status == 1 ){
						that.showOrderLoading = false;
						that.order.result = rs.data['ploss'];
						that.showOrderResult = true;
					} else {
						that.order.resultTimer = setTimeout(function(){
							that.timerDonwCallback();
						}, 2000);
					}
				})
			},
			timerSecondCallback(sec){
				this.order.current_t=this.micro_time-sec;
			},
			goback() {
				history.go(-1);
			},
			appendkline(t) {
				let that = this;
				this.greq('get', '/main/kline/pid/' + this.pid + '/t/' + t + '/depth/2', false, function(rs) {
					if ( t != that.current_interval ){
						return
					}
					if (rs.status) {
						that.info = rs.data.latest;
						that.txs = rs.data.txs;

						// that.chart.applyMoreData(rs.data.kline);
						// append
						let data = rs.data.kline;
						if (data.length) {
							let last_t = data[0]['timestamp'];
							let find = false;
							for (let i in that.klinedata) {
								if (that.klinedata[i]['timestamp'] == last_t) {
									find = i;
									break;
								}
							}
							if (find) {
								that.klinedata = that.klinedata.slice(0, find);
							}
							for (let item of data) {
								that.klinedata.push(item);
							}

							that.chart.applyNewData(that.klinedata);
						}
					}

					if(!that._isDestroyed){
						console.log('settimtout');
						that.timeout_kline = setTimeout(function() {
							that.appendkline(t);
						}, that.time_req)
					}
				});
			},
			kline(t) {
				let that = this;
				this.greq('get', '/main/kline/pid/' + this.pid + '/t/' + t, false, function(rs) {
					if ( t != that.current_interval ){
						return
					}
					
					if (rs.status) {
						that.klinedata = rs.data.kline;
						that.info = rs.data.latest;
						
						if( that.micro_time === 0 ){
							// 选择第一个
							that.micro_time = that.info.micro_contract[0]['time']
						}
						
						that.txs = rs.data.txs;
						// 设置y轴的精度
						let price = '' + that.info.c;
						let fix = price.length - price.indexOf('.') - 1;
						fix = fix < 2 ? 2 : fix;


						that.chart._chartStore._precision.price = fix // 这个7需要计算
						that.chart.adjustPaneViewport(true, true, true, true, true)

						that.chart.applyNewData(rs.data.kline);
					}

					that.appendkline(t);
				});
			},
			sidebarCallback(id) {
				// this.$router.push({
				// 	path: '/trade/' + pid,
				// 	hash: 'test'
				// });
				// this.klinedata = [];
				// this.pid = pid;
				// this.drawer = false;
				// this.chart.applyNewData([]);
				// this.info = {
				// 	'c': 0,
				// 	'o': 0,
				// 	'h': 0,
				// 	'l': 0,
				// 	'vol': 0,
				// 	'change': '0.00',
				// 	'title': '...',
				// 	'desc': '...',
				// };
				// if (this.timeout_kline) {
				// 	clearTimeout(this.timeout_kline);
				// 	this.timeout_kline = false;
				// }
				// this.kline(this.current_interval);
				this.$router.push('/to/trade/'+id);
			},
			
			formatNumber(){
				let n = parseInt(this.amount)
				if( isNaN(n) ){
					n = 0;
				}
				this.amount = n;
			},

			order_submit() {
				if( this.micro_amount < 1 ){
					this.gerror(this.$t('请输入金额'));
					return
				}
				
				if( this.order_btn_text == this.$t('订单正在提交')+'...' ){
					return
				}
				this.order_btn_text = this.$t('订单正在提交')+'...';
				
				let that = this;
				this.greq('post', '/api/micro_order', {pid:this.pid,t:this.micro_time,amount:this.micro_amount,type:this.micro_type}, function(rs){
					that.order_btn_text = that.$t('确认下单');
					if( rs.status ){
						that.showOrderTimerUI = true;
						// that.showOrderTimer = true;
						that.order.oid = rs.data.oid;
						that.order.buyprice = rs.data.buyprice;
					} else {
						that.gerror(rs.msg);
					}
				});
			},
		},
		mounted() {
			// chart.applyNewData([{
			// get api data
			this.chart = klinecharts.init('chart', {
				styles: {
					candle: {
						tooltip: {
							showRule: 'follow_cross',
							showType: 'rect',
						},
					},
					yAxis: {
						type: 'log'
					}
				}
			})


			this.chart.createIndicator('MA', false, {
				id: 'candle_pane'
			})
			this.chart.createIndicator("KDJ", false, {
				id: 'candle_pane2'
			})

			this.kline(this.current_interval);
			
			
			// hide footer
			if( this.from == 'contract' ){
				this.$parent.show_footer = false;
			}
		},
		beforeDestroy(){
			if( this.from == 'contract' ){
				this.$parent.show_footer = true;
			}
		},
		destroyed() {
			if (this.timeout_kline) {
				clearTimeout(this.timeout_kline);
			}
		}
	}
</script>

<style>
	.el-radio-button__orig-radio:checked+.el-radio-button__inner {
		box-shadow: none;
	}

	.trade_page .trade_nav_item {
		padding: 0px 10px;
		word-break: keep-all;
	}

	.trade_page .trade_nav_item div {
		padding: 4px 0px;
	}

	.trade_page .trade_nav_item .tni_active {
		color: #409EFF;
		border-bottom: 2px solid #409EFF;
	}

	.trade_page .el-tabs__header {
		display: none;
	}
	
	.trade_page .el-dialog__body{
		padding-top:0px !important;
	}
</style>
