<template>
	<div class="forgot_page">
		<div>
			<el-row class="m3">
				<el-col :span=10 style="font-size: 40px;text-indent: 10px;" class="c_blue tl">
					<router-link to="/login" class="c_blue">
						<i class="fa fa-angle-left" aria-hidden="true"></i>
					</router-link>
				</el-col>
				<el-col :span="14">&nbsp;</el-col>
			</el-row>
		</div>
		
		<div class="tl c_gray mt2">
			<div style="font-size: 30px;text-indent: 40px;">{{$t("重置密码")}}</div>
		</div>
		
		<div style="width: 66%;margin:0 auto;">
			<el-tabs v-model="type" :stretch="true" style="padding-top:40px;" class="login_form">
				<el-tab-pane :label="$t('手机')" name="phone">
					<div class="mt3">
						<el-input v-model="phone" :placeholder="$t('请输入手机号')" prefix-icon="areacode c_black2 tc"></el-input>
					</div>
				</el-tab-pane>
				
				<el-tab-pane :label="$t('邮箱')" name="email">
					<div class="mt3">
						<el-input v-model="email" :placeholder="$t('请输入邮箱')" prefix-icon="fa fa-inbox"></el-input>
					</div>
				</el-tab-pane>
			</el-tabs>
			
			<el-button type="primary" plain round class="mt3 w100" @click="sendCode">{{btn_code_text}}</el-button>
			
			<el-input class="mt3" v-model="code" :placeholder="$t('输入验证码')" prefix-icon="fa fa-file-code-o"></el-input>
			
			<el-input class="mt3" v-model="password" :placeholder="$t('请输入密码')" prefix-icon="fa fa-key" :show-password="true"></el-input>
			
			<el-button type="primary" round class="w100 mt4" @click="submit">{{$t('提交')}}</el-button>
		</div>
		
		
		
		
		<!-- 选择区号 -->
		<el-drawer :title="title" :visible.sync="drawer" :direction="direction" size="100%">
			<div class="mt2 p3">
				<el-input v-model="search" :placeholder="$t('搜索')"></el-input>
			</div>
			<div class="">
				<div class="tl m4" v-for="item of searchAreacode" v-bind:key="item.id" @click="areacode=item.code;drawer=false">
					{{item.title}} (+{{item.code}})
				</div>
			</div>
		</el-drawer>
	</div>
</template>

<script>
	export default {
		name: "ForgotView",
		data(){
			return {
				type: "phone",
				phone: "",
				areacode: "886",
				email: "",
				code: "",
				password: "",
				drawer: false,
				direction: "ttb",
				title: this.$t("选择区号"),
				areacodes: [],
				search: "",
				btn_code_text: this.$t('发送验证码'),
				timer_code: false,
			}
		},
		computed: {
			searchAreacode: function(){
				let s = this.search.toLowerCase().trim()
				console.log(s)
				return this.areacodes.filter(function(item){
					if(item.title.toLowerCase().indexOf(s) > -1 || item.code.indexOf(s)>-1){
						return true;
					}
					return false;
				});
		
			}
		},
		watch: {
			areacode: function(val){
				this.updateAreacode(val);
			}
		},
		methods: {
			updateAreacode(code){
				document.getElementsByClassName('areacode')[0].innerHTML = "+"+code;
			},
			initAreacodes(){
				// get areacods
				let langs = {
					zh: 1,
					en: 2,
					tw: 3,
					th: 4,
					jp: 5,
					es: 6,
					fr: 7,
					de: 8,
					ko: 9,
					vi: 10
				}
				let lang = this.glang()
				let langIndex = langs[lang]
				
				let areacodes = require('../json/areacodes.json')
				let newAreacodes = [];
				for(let row of areacodes){
					newAreacodes.push({
						code: row[0],
						title: row[langIndex]
					})
				}
				this.areacodes = newAreacodes;
			},
			
			// 发送验证码
			sendCode(){
				if( this.btn_code_text != this.$t('发送验证码') ){
					return false;
				}
				if( this.type == 'phone' && !this.phone ){
					this.gerror(this.$t('请输入手机号'))
					return
				}
				if( this.type == 'email' && !this.email){
					this.gerror(this.$t('请输入邮箱'))
					return
				}
				let account = this.type=='phone'?("+"+this.areacode+this.phone):this.email;
				
				let that = this;
				this.$parent.loading = true;
				this.greq('post', '/main/sendcode', {type:this.type,account:account}, function(rs){
					that.$parent.loading = false;
					if(rs.status){
						that.$message(that.$t('发送成功'));
						that.btn_code_text = "60s";
						that.timer_code = setInterval(function(){
							let t = parseInt(that.btn_code_text);
							if( t < 2 ){
								that.btn_code_text = that.$t('发送验证码');
								clearInterval(that.timer_code);
								that.timer_code = false;
							} else {
								t = t-1;
								that.btn_code_text = ""+t+'s';
							}
						}, 1000);
					} else {
						that.gerror(rs.msg);
					}
				}, function(){
					that.$parent.loading = false;
				})
			},
			
			
			// submit
			submit(){
				if( this.type == 'phone' && !this.phone ){
					this.gerror(this.$t('请输入手机号'))
					return
				}
				if( this.type == 'email' && !this.email){
					this.gerror(this.$t('请输入邮箱'))
					return
				}
				if( !this.code ){
					this.gerror(this.$t('输入验证码'));
					return
				}
				let account = this.type=='phone'?("+"+this.areacode+this.phone):this.email;
				let that = this;
				this.$parent.loading = true;
				this.greq('post', '/main/repassword', {type:this.type,account:account,code:this.code,pwd:this.password}, function(rs){
					that.$parent.loading = false;
					if( rs.status ){
						that.$message(rs.msg);
						// redirect to login
						setTimeout(function(){
							that.$router.push('/login');
						}, 2000);
					} else {
						that.gerror(rs.msg);
					}
				}, function(){
					that.$parent.loading = false;
				})
			}
		},
		mounted(){
			this.$parent.show_footer = false;
			this.initAreacodes();
			this.updateAreacode(this.areacode);
			let that = this;
			document.getElementsByClassName('areacode')[0].onclick=function(){
				that.drawer = true;
			}
		},
		beforeDestroy(){
			this.$parent.show_footer = true;
			if( this.timer_code ){
				clearInterval(this.timer_code);
			}
		}
	}
</script>

<style>
	.login_form .areacode{
		display: block;
		width: 60px;
		font-style: normal;
	}
</style>