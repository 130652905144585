<template>
	<div class="alerts_page">
		<div class="bg_white borderb">
			<el-row class="p2 c_gray" type="flex" align="middle">
				<el-col :span=4 class="tc" style="font-size:30px;">
					<router-link to="/" class="c_gray">
						<i class="fa fa-angle-left" aria-hidden="true"></i>
					</router-link>
				</el-col>
				<el-col :span=16 class="f16">
					{{$t("通知")}}
				</el-col>
				<el-col :span="4">&nbsp;</el-col>
			</el-row>
		</div>
		
		<div class="p3 bg_white borderb" v-for="item of data" v-bind:key="item.id" @click="$router.push('/alert_detail/'+item.id)">
			<el-row type="flex">
				<el-col :span="4">
					
					<div v-if="item.status==0">
						<div>
							<i class="fa fa-envelope-o f18"></i>
						</div>
						
						<span style="display:block;position: relative;width:10px;height:10px;border-radius:5px;margin:0 auto;top:-20px;left: 8px;" class="bg_yellow"></span>
					</div>
					<i class="fa fa-envelope-open-o f18 c_gray" v-if="item.status>0"></i>
				</el-col>
				<el-col :span="20" class="tl">
					<div class="fbold">{{item.title}}</div>
					<div class="mt1 c_gray">{{item.content}}</div>
					<div class="mt3 c_gray f12">{{item.add_time}}</div>
				</el-col>
			</el-row>
		</div>
		
		<div class="mt5 pb5" v-if="data.length==0">
			<div><img src="../assets/nodata.png" height="50px" alt=""></div>
			<div class="mt2 c_gray f12">{{$t('暂无数据')}}</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "AlertsView",
		data(){
			return {
				data: []
			}
		},
		methods: {
			getData(){
				let that = this;
				this.$parent.loading = true;
				this.greq('get', '/api/alerts', false, function(rs){
					that.$parent.loading = false;
					if( rs.status ){
						that.data = rs.data
					}
				}, function(){
					that.$parent.loading = false
				})
			}
		},
		mounted(){
			this.getData();
		}
	}
</script>

<style>
</style>