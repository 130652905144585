<template>
	<div>
		<svg style="transform: rotate(-90deg)" :width="width" :height="width" xmlns="http://www.w3.org/2000/svg">
			<circle :r="(width-radius)/2" :cy="width/2" :cx="width/2" :stroke-width="radius" :stroke="backgroundColor"
				fill="none" />
			<circle ref="$bar" :r="(width-radius)/2" :cy="width/2" :cx="width/2" :stroke="barColor"
				:stroke-width="radius" :stroke-linecap="isRound ? 'round' : 'square'"
				:stroke-dasharray="(width-radius)*3.14"
				:stroke-dashoffset="isAnimation ? (width-radius) * 3.14 : (width - radius) * 3.14 * (100 - progress2) / 100"
				fill="none" />
		</svg>

	</div>
</template>

<script>
	export default {
		props: {
			totalTime: [Number, String], // 最大时间
			autoRun: {
				type: Boolean,
				default: false,
			},
			width: [Number, String], // 圆的大小
			radius: [Number, String], // 进度条厚度
			progress: [Number, String], // 进度条百分比
			barColor: String, // 进度条颜色
			backgroundColor: String, // 背景颜色
			isAnimation: { // 是否是动画效果
				type: Boolean,
				default: true,
			},
			isRound: { // 是否是圆形画笔
				type: Boolean,
				default: true,
			},
			id: { // 组件的id，多组件共存时使用
				type: [String, Number],
				default: 1,
			},
			duration: { // 整个动画时长
				type: [String, Number],
				default: 1000,
			},
			delay: { // 延迟多久执行
				type: [String, Number],
				default: 200,
			},
			timeFunction: { // 动画缓动函数
				type: String,
				default: 'cubic-bezier(0.99, 0.01, 0.22, 0.94)',
			},
			secondCallback: {
				type: Function,
				default: function(){}
			},
			countDownCallBack: {
				type: Function,
				default: function(){}
			}
		},

		data() {
			return {
				idStr: `circle_progress_keyframes_${this.id}`,
				progress2: 0,
				current_t: 0, // 设置当前第几秒了
				timer: false,
				loss_focus_time: 0, // 记录失焦时时间戳
			}
		},

		beforeDestroy() {
			// 清除旧组件的样式标签
			document.getElementById(this.idStr) && document.getElementById(this.idStr).remove()
		},
		
		methods: {
			countDown(){
				if( this.current_t >= this.totalTime ){
					this.countDownCallBack()
					return
				}
				this.current_t += 1;
				let p = (this.current_t/this.totalTime).toFixed(3)*100;
				this.progress2 = p;
				
				this.secondCallback(this.current_t)
				
				let that = this;
				this.timer = setTimeout(function(){
					that.countDown();
				}, 1000)
			}
		},

		mounted() {
			this.progress2 = this.progress;
			console.log('init progress:' + this.progress2)
			if (this.isAnimation) {
				// 重复定义判断
				if (document.getElementById(this.idStr)) {
					console.warn('vue-circle-progress should not have same id style')
					document.getElementById(this.idStr).remove()
				}

				// 生成动画样式文件
				let style = document.createElement('style')
				style.id = this.idStr
				style.type = 'text/css'
				style.innerHTML =
					`
      @keyframes circle_progress_keyframes_name_${this.id} {
      from {stroke-dashoffset: ${(this.width - this.radius) * 3.14}px;}
      to {stroke-dashoffset: ${(this.width - this.radius) * 3.14 * (100 - this.progress) / 100}px;}}
      .circle_progress_bar${this.id} {animation: circle_progress_keyframes_name_${this.id} ${this.duration}ms ${this.delay}ms ${this.timeFunction} forwards;}`

				// 添加新样式文件
				document.getElementsByTagName('head')[0].appendChild(style)

				// 往svg元素中添加动画class
				this.$refs.$bar.classList.add(`circle_progress_bar${this.id}`)
			}
			
			if( this.autoRun ){
				this.countDown()
			}

			// 设置事件，失焦时，记录时间。
			let that = this;
			that.loss_focus_time = Date.parse(new Date());
			document.addEventListener('visibilitychange', function () {
				if (document.visibilityState === 'hidden') {
					// 浏览器失去焦点时
					
				} else if (document.visibilityState === 'visible') {
					// 浏览器获得焦点时
					let time_now = Date.parse(new Date());
					let offset = Math.floor((time_now - that.loss_focus_time - 1)/1000);
					that.current_t = offset;
				}
			});
		},
		beforeUnmount(){
			console.log('unmount');

			
		},
		destroyed(){
			console.log('destroy');
			if( this.timer ){
				clearTimeout(this.timer)
			}
			
			// 取消失焦事件订阅

		}
	}
</script>
