<template>
	<div class="content_page">
		<div class="bg_white">
			<el-row class="p2 c_black" type="flex" align="middle">
				<el-col :span=4 class="c_blue tc" style="font-size:30px;">
					<router-link :to="backurl" class="c_black">
						<i class="fa fa-angle-left" aria-hidden="true"></i>
					</router-link>
				</el-col>
				<el-col :span=16 class="f16">
					{{title}}
				</el-col>
				<el-col :span=4 class="">
					<span></span>
				</el-col>
			</el-row>
		</div>
		
		<div class="m4">
			<el-card class="box-card tl">
				<div v-html="content"></div>
			</el-card>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'ContentView',
		data(){
			return {
				content: "",
				title: "",
				backurl: "/"
			}
		},
		mounted: function(){
			// get content
			let that = this;
			this.greq('post', '/main/content', {id:this.id}, function(rs){
				if( !rs.status ){
					this.$message({message:rs.msg, type:'error'});
				} else {
					that.content = rs.data.content;
					that.title = rs.data.title;
					
					if( rs.data.type == 6 ){
						that.backurl = "/about"
					}
				}
			});
		},
		props: ['id']
	}
</script>

<style>
</style>